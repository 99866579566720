import { ReactNode, MouseEvent, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingStateIcon from "./LoadingStateIcon";

export interface CustomAccordionProps {
  children: ReactNode;
  title: string;
  isEditingAvailable: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  onSave?: (e: MouseEvent<HTMLButtonElement>) => void;
  toggleEdit?: () => void;
}

const CustomAccordion = ({
  children,
  title,
  isEditingAvailable,
  isEditing,
  isLoading,
  onSave,
  toggleEdit,
}: CustomAccordionProps) => {
  const [highlight, setHighlight] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    let highlightTimer: NodeJS.Timeout | null = null;

    if (!isLoading) {
      // When loading is complete, show highlight
      setHighlight(true);

      // Set a timer to turn off the highlight after 3 seconds
      highlightTimer = setTimeout(() => {
        setHighlight(false);
      }, 500);
    }

    // Cleanup the timer on component unmount or when effect re-runs
    return () => {
      if (highlightTimer) {
        clearTimeout(highlightTimer);
      }
    };
  }, [isLoading]);

  const getAccordionBackground = () =>
    highlight ? "rgba(40, 216, 169, 0.2)" : "rgba(102, 102, 102, 0.4)";

  const handleAccordionToggle = (e: MouseEvent) => {
    e.stopPropagation();

    if (isEditing || isLoading) {
      return;
    }

    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion
      expanded={isExpanded}
      sx={{
        backgroundColor: getAccordionBackground(),
        marginBottom: "10px",
        borderRadius: "4px",
        boxShadow: "none",
        width: "100%",
        overflow: "hidden",
        "&:before": { display: "none" },
        transition: "background-color 0.5s ease",
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: "60px",
          backgroundColor: getAccordionBackground(),
          borderRadius: "4px",
          "&.Mui-expanded": {
            minHeight: "80px",
          },
          cursor: isLoading ? "not-allowed" : "pointer",
          position: "relative",
        }}
        onClick={handleAccordionToggle}
      >
        <Box>
          <Typography variant="accordionTitle">{title}</Typography>
        </Box>

        <Box sx={{ color: "#fff", ml: 1 }}>
          <LoadingStateIcon
            // TODO: remove as boolean
            isDataLoading={isLoading as boolean}
            // showTick={showTick}
            showTick={false}
          />
        </Box>

        <Box
          sx={{
            marginLeft: 1,
            position: "absolute",
            right: "10px",
            bottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isEditingAvailable && onSave && toggleEdit && (
            <>
              {isExpanded && !isEditing && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEdit();
                  }}
                  size="small"
                  aria-label="Edit"
                  sx={{ color: "text.primary" }}
                >
                  <EditIcon />
                </IconButton>
              )}

              {isEditing && (
                <>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onSave(e);
                    }}
                    disabled={isLoading}
                    aria-label="Save"
                    sx={{ color: "text.primary" }}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SaveIcon />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleEdit();
                    }}
                    size="small"
                    disabled={isLoading}
                    aria-label="Cancel"
                    sx={{ color: "text.primary" }}
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
          <Box
            sx={{
              width: 22,
              height: 22,
              borderRadius: "50%",
              border: "3px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "transform 0.3s ease, opacity 0.5s ease",
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              opacity: !isEditing ? 1 : 0.5,
              marginLeft: "8px",
            }}
          >
            <ExpandMoreIcon sx={{ color: "#fff", fontSize: "16px" }} />
          </Box>
        </Box>
      </AccordionSummary>

      <Divider
        sx={{
          borderTop: "1px solid #FFFFFF",
          boxShadow: "none",
          marginBottom: "0px",
        }}
      />

      <AccordionDetails
        sx={{
          padding: "16px",
          backgroundColor: getAccordionBackground(),
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
