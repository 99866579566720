import { useRef, useState, MouseEvent } from "react";
import { Box } from "@mui/material";
import SubtitleDisplay from "./SubtitleDisplay";
import useSceneParallaxAnimation from "../../hooks/useSceneParallaxAnimation";
import useCharacterParallax from "../../hooks/useCharacterParallaxAnimation";
import { useCharacters } from "../../hooks/useCharacters";
import { CharacterImageUrls, Emotion } from "../../types/characterTypes";
import { SceneImageUrls } from "../../types/sceneTypes";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";

export const emotionTags = [
  "happy",
  "angry",
  "fear",
  "sad",
  "neutral",
] as const;

type StoryDisplayProps = {
  scriptOnlyMode: boolean;
  textBoxHeight: number;
  textContent: ExtendedScriptLineWithSceneId[];
  isPlaying: boolean;
  characterImageUrls: CharacterImageUrls;
  sceneImageUrls: SceneImageUrls;
};

const getExpressionForEmotion = (emotionText: string): Emotion => {
  const emotion = (emotionText || "").toLowerCase() as Emotion;
  return emotionTags.includes(emotion) ? emotion : "neutral";
};

const StoryDisplay = ({
  scriptOnlyMode,
  textBoxHeight,
  textContent,
  isPlaying,
  characterImageUrls,
  sceneImageUrls,
}: StoryDisplayProps) => {
  // Determine if the background URL is a video
  const isBackgroundVideo = false; // placeholder

  // Compute initial state outside of useState
  const initialImageUrls = isBackgroundVideo
    ? {
        backgroundUrl: sceneImageUrls["1.1"]?.background_video,
        foregroundUrl: sceneImageUrls["1.1"]?.foreground,
      }
    : {
        backgroundUrl: sceneImageUrls["1.1"]?.background,
        foregroundUrl: sceneImageUrls["1.1"]?.foreground,
      };

  const [imageUrls, setImageUrls] = useState(initialImageUrls);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const {
    characters,
    setCharacters,
    setUnavailableCharacters,
    updateCharactersWithImage,
  } = useCharacters();

  const sceneParallaxRefs = useSceneParallaxAnimation(isPlaying);
  const characterParallaxRefs = useCharacterParallax(characters, isPlaying);

  const charactersContainerRef = useRef<HTMLDivElement | null>(null);

  const handleSceneChange = (sceneId: string) => {
    console.log("SCENE - CHANGE", sceneId);
    if (!sceneId) return;

    setIsTransitioning(true);

    setTimeout(() => {
      setCharacters([]);

      const backgroundUrl = sceneImageUrls[sceneId]?.background;
      const foregroundUrl = sceneImageUrls[sceneId]?.foreground;
      setImageUrls({ backgroundUrl, foregroundUrl });

      setTimeout(() => setIsTransitioning(false), 500);
    }, 500);
  };

  const updateCharacterImages = (line: ExtendedScriptLineWithSceneId) => {
    const { characterName, emotion } = line;
    const trimmedCharacterName = characterName.trim().replace(/\s+/g, "");
    const newExpression = getExpressionForEmotion(emotion);

    try {
      const characterUrl =
        characterImageUrls[trimmedCharacterName]?.[newExpression];
      if (!characterUrl) {
        throw new Error(
          `Image URL not found for character ${trimmedCharacterName} with expression ${newExpression}`,
        );
      }
      updateCharactersWithImage(
        trimmedCharacterName,
        characterUrl,
        newExpression,
      );
    } catch (error) {
      console.error(
        `Failed to fetch image for character ${trimmedCharacterName} with expression '${newExpression}':`,
        error,
      );
      setUnavailableCharacters((prevSet) =>
        new Set(prevSet).add(trimmedCharacterName),
      );
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = (e.clientX - rect.left - rect.width / 2) / (rect.width / 2);
    const offsetY =
      (e.clientY - rect.top - rect.height / 2) / (rect.height / 2);

    /* Set the rotateMax to 0 to disable the rotation for now but going to add slight-motion separately to this... */
    const rotateMax = 0;
    const rotateX = offsetY * rotateMax;
    const rotateY = -offsetX * rotateMax;

    e.currentTarget.style.transition = "transform 0.1s ease-out";
    e.currentTarget.style.transform = `
      perspective(1600px)
      rotateX(${rotateX}deg)
      rotateY(${rotateY}deg)
      translateZ(0px)
    `;

    if (charactersContainerRef.current) {
      charactersContainerRef.current.style.transition =
        "transform 0.1s ease-out";
      charactersContainerRef.current.style.transform = `
        translate(-50%, -50%)
        translateZ(-100px)
      `;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transformOrigin: "center",
        transform: "scale(1.5)",
        "@media (max-width: 768px)": { transform: "scale(1)" },
        "@media (max-width: 480px)": { transform: "scale(0.8)" },
        opacity: scriptOnlyMode ? 0 : 1,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "calc(100% - 64px)",
          aspectRatio: "16/9",
          maxHeight: `calc(100vh - ${textBoxHeight}px - 120px)`,
          margin: "16px auto",
          position: "relative",
          opacity: isTransitioning ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
          overflow: "visible",
        }}
      >
        {/* 3D Pivot Container */}
        <Box
          onMouseMove={handleMouseMove}
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            perspective: "600px",
            transformStyle: "preserve-3d",
          }}
        >
          {/* Background */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-300px)",
            }}
          >
            {imageUrls.backgroundUrl && (
              <>
                {isBackgroundVideo ? (
                  <Box
                    ref={
                      sceneParallaxRefs.background as React.RefObject<HTMLVideoElement>
                    }
                    component="video"
                    src={imageUrls.backgroundUrl}
                    autoPlay
                    loop
                    muted
                    playsInline
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "translate(-50%, -50%)",
                      zIndex: 0,
                      transition: "filter 0.3s ease-in-out",
                    }}
                  />
                ) : (
                  <Box
                    ref={
                      sceneParallaxRefs.background as React.RefObject<HTMLImageElement>
                    }
                    component="img"
                    src={imageUrls.backgroundUrl}
                    alt="background"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "translate(-50%, -50%)",
                      zIndex: 0,
                      transition: "filter 0.3s ease-in-out",
                    }}
                  />
                )}
              </>
            )}
          </Box>

          {/* Storyboard-Frame */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-150px)",
            }}
          >
            <Box
              ref={
                sceneParallaxRefs.storyboardFrame as React.RefObject<HTMLImageElement>
              }
              component="img"
              src="/images/storyboard-frame.png"
              alt="Storyboard Frame"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            />
          </Box>

          {/* Characters */}
          <Box
            ref={charactersContainerRef}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              transform: "translate(-50%, -50%) translateZ(-100px)",
              transformStyle: "preserve-3d",
            }}
          >
            {characters.length > 0 &&
              characters.map((character, index) => {
                if (
                  !character ||
                  !character.name ||
                  !character.expression ||
                  !character.url ||
                  !character.hasImage
                ) {
                  console.warn("Character data missing:", character);
                  return null;
                }

                const opacity = character.isVisible ? 1 : 0;
                const expression = character.expression;

                return (
                  <Box
                    key={character.name}
                    ref={(el: HTMLDivElement | null) => {
                      characterParallaxRefs.current[index] = el;
                    }}
                    component="img"
                    src={character.url}
                    alt={`${character.name} - ${expression}`}
                    sx={{
                      position: "absolute",
                      top: "55%",
                      left: `${character.position}%`,
                      width: "80%",
                      height: "auto",
                      objectFit: "contain",
                      transform: "translate(-50%, -50%)",
                      opacity,
                      transition:
                        "opacity 0.3s ease-in-out, filter 0.3s ease-in-out",
                      filter: character.isSpeaking
                        ? "none"
                        : "blur(2px) brightness(0.7)",
                    }}
                    onError={(e) => {
                      console.error(
                        `Image failed to load for ${character.name} (${expression}):`,
                        e,
                      );
                      setCharacters((prev) =>
                        prev.filter((c) => c.name !== character.name),
                      );
                      setUnavailableCharacters((prevSet) => {
                        const newSet = new Set(prevSet);
                        newSet.add(character.name);
                        return newSet;
                      });
                    }}
                  />
                );
              })}
          </Box>

          {/* Foreground */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transformStyle: "preserve-3d",
              transform: "translateZ(-75px)",
              zIndex: 4,
            }}
          >
            {imageUrls.foregroundUrl && (
              <Box
                ref={
                  sceneParallaxRefs.foreground as React.RefObject<HTMLImageElement>
                }
                component="img"
                src={imageUrls.foregroundUrl}
                alt="foreground"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  filter: `blur(1px) drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.7))`,
                  transform: `translate(-50%, -50%)`,
                  zIndex: 4,
                }}
              />
            )}
          </Box>
        </Box>

        {/* Subtitles */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 9999,
          }}
        >
          <SubtitleDisplay
            textContent={textContent}
            isPlaying={isPlaying}
            onSceneChange={handleSceneChange}
            updateCharacterImages={updateCharacterImages}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StoryDisplay;
