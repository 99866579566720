import { useEffect, useState } from "react";
import { Box, CircularProgress, Divider } from "@mui/material";
import StoryDisplay from "../components/viewer/StoryDisplay";
import ViewerControls from "../components/viewer/ViewerControls";
import ScrollTextBox from "../components/viewer/ScrollTextBox";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import AlertMessage from "../components/AlertMessage";
import { CharacterImageUrls } from "../types/characterTypes";
import { SceneImageUrls, SceneType } from "../types/sceneTypes";
import { ExtendedScriptLineWithSceneId } from "../types/scriptTypes";

const StoryViewer = () => {
  const scriptPanelWidth = 400;
  const contentMargin = "80px";

  const [isPlaying, setIsPlaying] = useState(false);
  const [scriptOnlyMode, setScriptOnlyMode] = useState(false);
  const [alert, setAlert] = useState("");
  const [audioFiles, setAudioFiles] = useState({
    music: "",
    soundscape: "",
  });

  const { getToken } = useAuth();
  const { storyId } = useParams<{ storyId: string }>();

  const [scriptLines, setScriptLines] =
    useState<ExtendedScriptLineWithSceneId[]>();
  const [characterImageUrls, setCharacterImageUrls] =
    useState<CharacterImageUrls>();
  const [sceneImageUrls, setSceneImageUrls] = useState<SceneImageUrls>();

  const fetchStory = async () => {
    try {
      const token = await getToken();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const formattedData: ExtendedScriptLineWithSceneId[] =
        data.script_lines.flatMap((scene: SceneType) =>
          scene.scriptLines.map((line) => ({
            ...line,
            sceneId: scene.sceneId,
          })),
        );

      setScriptLines(formattedData);
      setCharacterImageUrls(data.character_image_urls);
      setSceneImageUrls(data.scene_image_urls);
    } catch (error) {
      console.error("Error fetching story data", error);
      setAlert("Error fetching story data");
    }
  };

  const fetchAudio = async () => {
    try {
      const token = await getToken();
      const musicPath = `generation_id_${storyId}/music.mp3`;
      const soundscapePath = `generation_id_${storyId}/soundscape.mp3`;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get-audio`,
        {
          storyId,
          audioPaths: {
            soundscape_path: soundscapePath,
            music_path: musicPath,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      let alertsMsg = "";
      const { soundscape, music } = response.data.urls;
      if (!music) alertsMsg += "No Music found! ";
      if (!soundscape) alertsMsg += "No Soundscape found! ";
      if (alertsMsg) setAlert(alertsMsg.trim());

      setAudioFiles({
        soundscape: soundscape || "",
        music: music || "",
      });
    } catch (error) {
      console.error("Error fetching audio files", error);
      setAlert("Error fetching audio files");
    }
  };

  useEffect(() => {
    if (storyId) {
      fetchStory();
      fetchAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#121212",
        boxSizing: "border-box",
        padding: contentMargin,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {scriptLines && characterImageUrls && sceneImageUrls ? (
        <>
          {/* Left Container */}
          <Box
            sx={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "80px",
            }}
          >
            <StoryDisplay
              scriptOnlyMode={scriptOnlyMode}
              textBoxHeight={25}
              textContent={scriptLines}
              isPlaying={isPlaying}
              characterImageUrls={characterImageUrls}
              sceneImageUrls={sceneImageUrls}
            />
          </Box>

          {/* Right Container */}
          <Box
            sx={{
              width: `${scriptPanelWidth}px`,
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                backgroundColor: "white",
                width: "2px",
                marginRight: "40px",
              }}
            />
            <Box
              sx={{
                flex: 1,
                height: "100%",
                backgroundColor: "#1e1e1e",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "16px",
                }}
              >
                <ScrollTextBox
                  textContent={scriptLines}
                  isPlaying={isPlaying}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "8px",
                  marginBottom: "24px",
                }}
              >
                <ViewerControls
                  isPlaying={isPlaying}
                  scriptOnlyMode={scriptOnlyMode}
                  audioFiles={audioFiles}
                  setIsPlaying={setIsPlaying}
                  onToggleScriptMode={() => setScriptOnlyMode((prev) => !prev)}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <CircularProgress />
      )}

      {alert && <AlertMessage severity="error" message={alert} />}
    </Box>
  );
};

export default StoryViewer;
