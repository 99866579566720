interface CharacterDetailProps {
  children: React.ReactNode;
}

const CharacterDetail = ({ children }: CharacterDetailProps) => (
  <span style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}>
    {children}
  </span>
);
export default CharacterDetail;
