import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import MapIcon from "@mui/icons-material/Map";
import PeopleIcon from "@mui/icons-material/People";
import TimelineIcon from "@mui/icons-material/Timeline";
import MovieIcon from "@mui/icons-material/Movie";
import ScriptIcon from "@mui/icons-material/Description";

import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import TitleDialog from "./dialogs/TitleDialog";
import GenresDialog from "./dialogs/GenresDialog";
import SettingDialog from "./dialogs/SettingDialog";
import StoryBeatsDialog from "./dialogs/StoryBeatsDialog";
import CharactersDialog from "./dialogs/CharactersDialog";
import ScenesDialog from "./dialogs/ScenesDialog";

interface BottomBarStoryGenerationProps {
  currentStep: number;
  sections: string[];
}

const iconMap: Record<string, React.ReactNode> = {
  title: <HomeIcon />,
  genres: <CategoryIcon />,
  setting: <MapIcon />,
  characters: <PeopleIcon />,
  storyBeats: <TimelineIcon />,
  scenes: <MovieIcon />,
  script: <ScriptIcon />,
};

const BottomBarStoryGeneration = ({
  currentStep,
  sections,
}: BottomBarStoryGenerationProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { storyGenerationState } = useStoryGenerationContext();

  const [selectedDialog, setSelectedDialog] = useState("");
  const [content, setContent] = useState();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "-40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        backgroundColor: "rgba(102, 102, 102, 0.4)",
        border: "2px solid rgba(217, 217, 217, 0.28)",
        borderRadius: "12px 12px 0 0",
        padding: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(8px)",
        height: "80px",
        zIndex: 1000,
      }}
    >
      {sections.map((section, index) => {
        const key = section.toLowerCase();
        const icon = iconMap[key] || <HomeIcon />;

        const selectedValue = storyGenerationState?.data?.find(
          (state) => state.section === section,
        )?.value;
        const status = selectedValue
          ? "completed"
          : index === currentStep
            ? "current"
            : "future";

        const isFuture = index > currentStep;

        return (
          <Tooltip key={key} title={section} arrow>
            <span>
              <IconButton
                onClick={() => {
                  if (status === "completed") {
                    setContent(selectedValue);
                    setSelectedDialog(section);
                    setIsOpen(true);
                  }
                }}
                disabled={isFuture}
                sx={{
                  width: 56,
                  height: 56,
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    status === "current"
                      ? "linear-gradient(135deg, #28D8A9, #5FE692)"
                      : status === "completed"
                        ? "linear-gradient(135deg, rgba(40, 216, 169, 0.5), rgba(95, 230, 146, 0.5))"
                        : "transparent",
                  border:
                    status === "future"
                      ? "1px solid rgba(255, 255, 255, 0.2)"
                      : status === "current"
                        ? "2px solid #FFFFFF"
                        : "none",
                  color:
                    status === "current"
                      ? "#ffffff"
                      : status === "completed"
                        ? "rgba(255, 255, 255, 0.6)"
                        : "transparent",
                  boxShadow:
                    status === "current"
                      ? "0 4px 6px rgba(0, 0, 0, 0.4)"
                      : "none",
                  transition: "all 0.3s ease",
                  cursor: isFuture ? "not-allowed" : "pointer",
                  "&:hover": {
                    background:
                      status === "current"
                        ? "linear-gradient(135deg, #28D8A9, #5FE692)"
                        : status === "completed"
                          ? "linear-gradient(135deg, rgba(40, 216, 169, 0.6), rgba(95, 230, 146, 0.6))"
                          : "transparent",
                    color:
                      status === "current"
                        ? "#ffffff"
                        : status === "completed"
                          ? "rgba(255, 255, 255, 0.8)"
                          : "transparent",
                  },
                }}
              >
                {!isFuture && icon}
              </IconButton>
            </span>
          </Tooltip>
        );
      })}
      {content && (
        <>
          {selectedDialog === "Title" && (
            <TitleDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          {selectedDialog === "Genres" && (
            <GenresDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          {selectedDialog === "Setting" && (
            <SettingDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          {selectedDialog === "StoryBeats" && (
            <StoryBeatsDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          {selectedDialog === "Characters" && (
            <CharactersDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          {selectedDialog === "Scenes" && (
            <ScenesDialog
              content={content}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default BottomBarStoryGeneration;
