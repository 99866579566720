import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CharacterDetail from "../../../CharacterDetail";
import { Character } from "../../../../types/characterTypes";

interface SettingDialogProps {
  content: Character[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const CharactersDialog = ({
  content,
  isOpen,
  setIsOpen,
}: SettingDialogProps) => {
  console.group(content);
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "600px",
          backgroundColor: "rgba(27,27,27,0.95)",
          border: "2px solid rgba(217,217,217,0.28)",
        },
      }}
    >
      <DialogTitle
        sx={{
          margin: 0,
          padding: "16px",
          color: "#fff",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        Characters
        <IconButton
          aria-label="close"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {content.map((character) => (
          <Box sx={{ mb: 2 }} key={character.scriptName}>
            <Accordion
              sx={{
                backgroundColor: "rgba(102,102,102,0.4)",
                borderRadius: "4px",
                boxShadow: "none",
                "&:before": { display: "none" },
                transition: "background-color 0.3s ease",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                sx={{
                  backgroundColor: "rgba(102,102,102,0.4)",
                  borderRadius: "4px",
                  color: "#fff",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#fff", fontSize: "1rem" }}
                >
                  {character.scriptName}
                </Typography>
              </AccordionSummary>
              <Divider sx={{ borderColor: "#ffffff" }} />
              <AccordionDetails
                sx={{ backgroundColor: "rgba(102,102,102,0.2)" }}
              >
                <Box
                  sx={{ color: "rgba(255, 255, 255, 0.7)", textAlign: "left" }}
                >
                  <Typography variant="body2">
                    <CharacterDetail>Backstory:</CharacterDetail>{" "}
                    {character.background.backstory}
                    <br />
                    <CharacterDetail>Core Values:</CharacterDetail>{" "}
                    {character.background.coreValues}
                    <br />
                    <CharacterDetail>Fears and Flaws:</CharacterDetail>{" "}
                    {character.background.fearsAndFlaws}
                    <br />
                    <CharacterDetail>Motivations:</CharacterDetail>{" "}
                    {character.background.motivations}
                    <br />
                    <CharacterDetail>Description:</CharacterDetail>{" "}
                    {character.description}
                    <br />
                    <CharacterDetail>Location:</CharacterDetail>{" "}
                    {character.location}
                    <br />
                    <CharacterDetail>Name:</CharacterDetail> {character.name}
                    <br />
                    <CharacterDetail>Character Arc Goal:</CharacterDetail>{" "}
                    {character.narrativeRole.characterArcGoal}
                    <br />
                    <CharacterDetail>Symbolic Role:</CharacterDetail>{" "}
                    {character.narrativeRole.symbolicRole}
                    <br />
                    <CharacterDetail>Narrative Tropes:</CharacterDetail>{" "}
                    {character.narrativeTropes}
                    <br />
                    <CharacterDetail>Age:</CharacterDetail>{" "}
                    {character.physicalProfile.age}
                    <br />
                    <CharacterDetail>Appearance:</CharacterDetail>{" "}
                    {character.physicalProfile.appearance}
                    <br />
                    <CharacterDetail>Gender:</CharacterDetail>{" "}
                    {character.physicalProfile.gender}
                    <br />
                    <CharacterDetail>Specific Traits:</CharacterDetail>{" "}
                    {character.physicalProfile.specificTraits}
                    <br />
                    <CharacterDetail>
                      Emotional Disposition:
                    </CharacterDetail>{" "}
                    {character.psychologicalProfile.emotionalDisposition}
                    <br />
                    <CharacterDetail>Intellectual Style:</CharacterDetail>{" "}
                    {character.psychologicalProfile.intellectualStyle}
                    <br />
                    <CharacterDetail>Moral Alignment:</CharacterDetail>{" "}
                    {character.psychologicalProfile.moralAlignment}
                    <br />
                    <CharacterDetail>Personality:</CharacterDetail>{" "}
                    {character.psychologicalProfile.personality}
                    <br />
                    <CharacterDetail>Strengths:</CharacterDetail>{" "}
                    {character.psychologicalProfile.strengths}
                    <br />
                    <CharacterDetail>Style of Speech:</CharacterDetail>{" "}
                    {character.psychologicalProfile.styleOfSpeech}
                    <br />
                    <CharacterDetail>Weaknesses:</CharacterDetail>{" "}
                    {character.psychologicalProfile.weaknesses}
                    <br />
                    <CharacterDetail>Purpose:</CharacterDetail>{" "}
                    {character.purpose}
                    <br />
                    <CharacterDetail>Relevance:</CharacterDetail>{" "}
                    {character.relevance}
                    <br />
                    <CharacterDetail>Role:</CharacterDetail> {character.role}
                    <br />
                    <CharacterDetail>Script Name:</CharacterDetail>{" "}
                    {character.scriptName}
                    <br />
                    <CharacterDetail>Setting Tropes:</CharacterDetail>{" "}
                    {character.settingTropes}
                    <br />
                    <CharacterDetail>Species:</CharacterDetail>{" "}
                    {character.species}
                    <br />
                    <CharacterDetail>Type:</CharacterDetail> {character.type}
                    <br />
                    <CharacterDetail>Visual Description:</CharacterDetail>{" "}
                    {character.visualDescription}
                    <br />
                    <CharacterDetail>Key Relationships:</CharacterDetail>
                    <br />
                    {character.relationalDynamics.keyRelationships.map(
                      (relationship, relIndex: number) => (
                        <span key={relIndex}>
                          <CharacterDetail>- Script name:</CharacterDetail>{" "}
                          {relationship.scriptName}{" "}
                          <CharacterDetail>Relationship:</CharacterDetail>{" "}
                          {relationship.relationship}
                          <br />
                        </span>
                      ),
                    )}
                    <CharacterDetail>Reputation:</CharacterDetail>{" "}
                    {character.relationalDynamics.reputation}
                    <br />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default CharactersDialog;
