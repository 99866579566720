import { Box, Typography } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import StoryFormat from "./StoryFormat";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";

type ScrollTextBoxProps = {
  textContent: ExtendedScriptLineWithSceneId[];
  isPlaying: boolean;
};

const ScrollTextBox = ({ textContent, isPlaying }: ScrollTextBoxProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [scrollSpeed, setScrollSpeed] = useState<number>(0);

  const cumulativeScroll = useRef(0);
  const lastTimeRef = useRef<number>(performance.now());

  useLayoutEffect(() => {
    if (!scrollContainerRef.current || textContent.length === 0) return;

    const totalDurationMs = textContent.reduce(
      (sum, line) => sum + (line.displayTime || 0),
      0,
    );

    if (totalDurationMs > 0) {
      const maxScrollDistance =
        scrollContainerRef.current.scrollHeight -
        scrollContainerRef.current.clientHeight;

      const calculatedSpeed = maxScrollDistance / (totalDurationMs / 1000);
      setScrollSpeed(calculatedSpeed);
    }
  }, [textContent]);

  useEffect(() => {
    let requestId: number;

    const animateScroll = (time: number) => {
      const delta = time - lastTimeRef.current;
      lastTimeRef.current = time;

      if (isPlaying && scrollContainerRef.current) {
        const increment = (delta / 1000) * scrollSpeed;
        cumulativeScroll.current += increment;
        scrollContainerRef.current.scrollTop = cumulativeScroll.current;
      }

      requestId = requestAnimationFrame(animateScroll);
    };

    requestId = requestAnimationFrame(animateScroll);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [isPlaying, scrollSpeed]);

  const renderContent = () => {
    if (textContent.length > 0) {
      return textContent.map((line, index) => (
        <StoryFormat key={index} line={line} index={index} />
      ));
    }
    return (
      <Typography sx={{ color: "white", textAlign: "center" }}>
        Loading script...
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#1e1e1e",
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
      }}
    >
      {/* Scrollable text area with auto-scroll */}
      <Box
        ref={scrollContainerRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          color: "white",
          fontSize: "14px",
          lineHeight: "1.5",
          paddingRight: "4px",
          width: "100%",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#1e1e1e",
          },
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default ScrollTextBox;
