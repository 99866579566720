import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogWrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
}

const DialogWrapper = ({
  children,
  isOpen,
  setIsOpen,
  title,
}: DialogWrapperProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "600px",
          backgroundColor: "rgba(27,27,27,0.95)",
          border: "2px solid rgba(217,217,217,0.28)",
        },
      }}
    >
      <DialogTitle
        sx={{
          margin: 0,
          padding: "16px",
          color: "#fff",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
