import { Box, CircularProgress, Typography } from "@mui/material";

type StepIndicatorProps = {
  index: number;
  step: string;
  currentStep: number;
  stopCircularLoading: boolean;
};

const StepIndicator = ({
  index,
  step,
  currentStep,
  stopCircularLoading,
}: StepIndicatorProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
    }}
  >
    <Box sx={{ position: "relative", width: "20px", height: "20px" }}>
      {/* Render the step as highlighted but without the spinner for the "Title" step */}
      {index === currentStep && index === 0 ? (
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.main, // Green fill
            border: "3px solid rgb(255, 255, 255)", // White outline
            transition: "background-color 0.5s ease-in-out",
          })}
        />
      ) : index > 0 && !stopCircularLoading && index === currentStep ? (
        <CircularProgress size={20} sx={{ color: "white" }} />
      ) : (
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor:
              index < currentStep
                ? theme.palette.primary.main
                : index === currentStep
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.5)",
            border:
              index === currentStep
                ? "none"
                : index <= currentStep
                  ? "3px solid rgb(255, 255, 255)"
                  : "none",
            transition: "background-color 0.5s ease-in-out",
          })}
        />
      )}
    </Box>
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        top: "25px",
        width: "100px",
        textAlign: "center",
        fontSize: index <= currentStep ? "13px" : "12px",
        color:
          index <= currentStep
            ? "rgb(255, 255, 255)"
            : "rgba(255, 255, 255, 0.5)",
      }}
    >
      {step}
    </Typography>
    <Box
      component="img"
      src="logos/nib-icon.png"
      alt="Nib Icon"
      sx={{
        marginTop: "30px",
        width: "30px",
        height: "auto",
        transform: "rotate(270deg)",
        visibility: currentStep === index ? "visible" : "hidden",
      }}
    />
  </Box>
);

export default StepIndicator;
