import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogWrapper from "./DialogWrapper";
import { GenresContent } from "../../../../types/genresTypes";

interface GenresDialogProps {
  content: GenresContent[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const GenresDialog = ({ content, isOpen, setIsOpen }: GenresDialogProps) => {
  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen} title="Genres">
      <Box sx={{ mb: 2 }}>
        <Accordion
          sx={{
            backgroundColor: "rgba(102,102,102,0.4)",
            borderRadius: "4px",
            boxShadow: "none",
            "&:before": { display: "none" },
            transition: "background-color 0.3s ease",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              backgroundColor: "rgba(102,102,102,0.4)",
              borderRadius: "4px",
              color: "#fff",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", color: "#fff", fontSize: "1rem" }}
            >
              Style Genre
            </Typography>
          </AccordionSummary>
          <Divider sx={{ borderColor: "#ffffff" }} />
          <AccordionDetails sx={{ backgroundColor: "rgba(102,102,102,0.2)" }}>
            <Typography sx={{ color: "#fff", fontSize: "0.85rem" }}>
              {content[0].styleGenre?.styleGenre}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Accordion
          sx={{
            backgroundColor: "rgba(102,102,102,0.4)",
            borderRadius: "4px",
            boxShadow: "none",
            "&:before": { display: "none" },
            transition: "background-color 0.3s ease",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              backgroundColor: "rgba(102,102,102,0.4)",
              borderRadius: "4px",
              color: "#fff",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", color: "#fff", fontSize: "1rem" }}
            >
              External Content Genre
            </Typography>
          </AccordionSummary>
          <Divider sx={{ borderColor: "#ffffff" }} />
          <AccordionDetails sx={{ backgroundColor: "rgba(102,102,102,0.2)" }}>
            <Typography sx={{ color: "#fff", fontSize: "0.85rem" }}>
              {content[0].externalContentGenre?.externalContentGenre}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </DialogWrapper>
  );
};

export default GenresDialog;
