import { Typography } from "@mui/material";
import DialogWrapper from "./DialogWrapper";

interface TitleDialogProps {
  content: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const TitleDialog = ({ content, isOpen, setIsOpen }: TitleDialogProps) => {
  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen} title="Title">
      <Typography>{content}</Typography>
    </DialogWrapper>
  );
};

export default TitleDialog;
