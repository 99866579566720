import { Box, Typography, Button, CircularProgress } from "@mui/material";
import PreviewBox from "../components/PreviewBox";
import PageLayout from "../components/layout/PageLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";

type LibraryStoryCards = {
  generation_id: string;
  story_id: string;
  created_at: string;
};

const Library = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const [stories, setStories] = useState<LibraryStoryCards[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAllStories = async () => {
      setIsLoading(true);
      const token = await getToken();
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/stories`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          },
        );

        setStories(response.data);
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    loadAllStories();
  }, [getToken]);

  return (
    <PageLayout title="Library">
      {isLoading ? (
        <Box sx={{ textAlign: "center", marginTop: "80px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{ marginTop: "16px", marginLeft: "32px", marginRight: "32px" }}
        >
          <Typography
            variant="h5"
            sx={{ marginTop: "16px", marginBottom: "8px" }}
          >
            YOUR STORIES
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            {stories?.map((story) => (
              <PreviewBox
                key={story.generation_id}
                storyId={story.generation_id}
                title={story.story_id}
                createdAt={story.created_at}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid #28D8A9",
              borderRadius: "12px",
              padding: "20px",
              marginTop: "32px",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#28D8A9", marginBottom: "16px" }}
            >
              CREATE YOUR OWN STORY!
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: "32px", color: "#D9D9D9" }}
            >
              Flex your creative muscles - have a go at creating your own dream
              story using our generative tools!
            </Typography>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(90deg, #28D8A9 0%, #5FE692 100%)",
                color: "black",
                padding: "10px 20px",
                fontSize: "16px",
                border: "2px solid #5DF5AC",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #1E1A1E 0%, #44A669 100%)",
                  color: "#5DF5AC",
                },
              }}
              onClick={() => navigate("/new-story")}
            >
              CREATE
            </Button>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};

export default Library;
