import axios from "axios";

interface UploadAudioType {
  token: string;
  storyId: string;
  audioType: "soundscape" | "music";
  fileUrl: string;
}

const uploadAudio = async ({
  token,
  storyId,
  audioType,
  fileUrl,
}: UploadAudioType) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/upload-audio`,
      {
        storyId,
        audioType,
        fileUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );

    console.log(`${audioType} generation complete!`);
    return;
  } catch (error) {
    console.error(`Error checking ${audioType} generation status:`, error);
  }
};

export default uploadAudio;
