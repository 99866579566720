import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogWrapper from "./DialogWrapper";
import { Scene } from "../../../../types/sceneTypes";
import { formatFieldKey } from "../../../../utils/formatFieldKey";

interface ScenesDialogProps {
  content: Scene[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ScenesDialog = ({ content, isOpen, setIsOpen }: ScenesDialogProps) => {
  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen} title="Scenes">
      {content.map((scene) => (
        <Box sx={{ mb: 2 }} key={scene.sceneId}>
          <Accordion
            sx={{
              backgroundColor: "rgba(102,102,102,0.4)",
              borderRadius: "4px",
              boxShadow: "none",
              "&:before": { display: "none" },
              transition: "background-color 0.3s ease",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{
                backgroundColor: "rgba(102,102,102,0.4)",
                borderRadius: "4px",
                color: "#fff",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#fff", fontSize: "1rem" }}
              >
                Scene {scene.sceneId}
              </Typography>
            </AccordionSummary>
            <Divider sx={{ borderColor: "#ffffff" }} />
            <AccordionDetails sx={{ backgroundColor: "rgba(102,102,102,0.2)" }}>
              <Box
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography variant="body2">
                  <strong>{formatFieldKey("description")}:</strong>{" "}
                  {scene.description}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("location")}:</strong>{" "}
                  {scene.location}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("timeOfDay")}:</strong>{" "}
                  {scene.timeOfDay}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("characters")}:</strong>{" "}
                  {scene.characterNames?.join(", ")}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("instructions")}:</strong>
                </Typography>
                <ol
                  style={{
                    fontSize: "14px",
                    color: "#fff",
                    marginLeft: "20px",
                  }}
                >
                  {scene.instructions.map((instruction, i) => (
                    <li key={i}>{instruction}</li>
                  ))}
                </ol>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </DialogWrapper>
  );
};

export default ScenesDialog;
