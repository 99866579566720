import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import CustomAccordion from "../accordion/CustomAccordion";
import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import SectionWrapper from "./SectionWrapper";
import CharacterDetail from "../../CharacterDetail";
import { Character } from "../../../types/characterTypes";
import startCharacterBlueprintGeneration from "../../../utils/startCharacterBlueprintGeneration";

interface CharactersStageProps {
  handleNext: () => Promise<void>;
  storyId: string;
  setAlert: (alertMsg: string) => void;
}

const CharactersStage = ({
  handleNext,
  storyId,
  setAlert,
}: CharactersStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState } = useStoryGenerationContext();

  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const characters =
    storyGenerationState.data?.find((state) => state.section === "Characters")
      ?.value || [];

  useEffect(() => {
    if (characters?.length > 0) {
      setLoading(false);
    }
  }, [characters]);

  return (
    <SectionWrapper
      canProceed={!loading}
      handleNext={async () => {
        const token = (await getToken()) as string;
        startCharacterBlueprintGeneration({ token, storyId, setAlert });
        await handleNext();
      }}
    >
      {characters.length === 0 ? (
        <CustomAccordion
          title="Characters"
          isEditingAvailable={false}
          isLoading={loading}
        >
          <Typography variant="body2">No characters available yet.</Typography>
        </CustomAccordion>
      ) : (
        characters.map((character: Character) => (
          <CustomAccordion
            key={character.scriptName}
            title={character.name ? character.name : "Characters"}
            isEditingAvailable={false}
            isLoading={loading}
          >
            <Box sx={{ color: "rgba(255, 255, 255, 0.7)", textAlign: "left" }}>
              <Typography variant="body2">
                <CharacterDetail>Backstory:</CharacterDetail>{" "}
                {character.background.backstory}
                <br />
                <CharacterDetail>Core Values:</CharacterDetail>{" "}
                {character.background.coreValues}
                <br />
                <CharacterDetail>Fears and Flaws:</CharacterDetail>{" "}
                {character.background.fearsAndFlaws}
                <br />
                <CharacterDetail>Motivations:</CharacterDetail>{" "}
                {character.background.motivations}
                <br />
                <CharacterDetail>Description:</CharacterDetail>{" "}
                {character.description}
                <br />
                <CharacterDetail>Location:</CharacterDetail>{" "}
                {character.location}
                <br />
                <CharacterDetail>Name:</CharacterDetail> {character.name}
                <br />
                <CharacterDetail>Character Arc Goal:</CharacterDetail>{" "}
                {character.narrativeRole.characterArcGoal}
                <br />
                <CharacterDetail>Symbolic Role:</CharacterDetail>{" "}
                {character.narrativeRole.symbolicRole}
                <br />
                <CharacterDetail>Narrative Tropes:</CharacterDetail>{" "}
                {character.narrativeTropes}
                <br />
                <CharacterDetail>Age:</CharacterDetail>{" "}
                {character.physicalProfile.age}
                <br />
                <CharacterDetail>Appearance:</CharacterDetail>{" "}
                {character.physicalProfile.appearance}
                <br />
                <CharacterDetail>Gender:</CharacterDetail>{" "}
                {character.physicalProfile.gender}
                <br />
                <CharacterDetail>Specific Traits:</CharacterDetail>{" "}
                {character.physicalProfile.specificTraits}
                <br />
                <CharacterDetail>Emotional Disposition:</CharacterDetail>{" "}
                {character.psychologicalProfile.emotionalDisposition}
                <br />
                <CharacterDetail>Intellectual Style:</CharacterDetail>{" "}
                {character.psychologicalProfile.intellectualStyle}
                <br />
                <CharacterDetail>Moral Alignment:</CharacterDetail>{" "}
                {character.psychologicalProfile.moralAlignment}
                <br />
                <CharacterDetail>Personality:</CharacterDetail>{" "}
                {character.psychologicalProfile.personality}
                <br />
                <CharacterDetail>Strengths:</CharacterDetail>{" "}
                {character.psychologicalProfile.strengths}
                <br />
                <CharacterDetail>Style of Speech:</CharacterDetail>{" "}
                {character.psychologicalProfile.styleOfSpeech}
                <br />
                <CharacterDetail>Weaknesses:</CharacterDetail>{" "}
                {character.psychologicalProfile.weaknesses}
                <br />
                <CharacterDetail>Purpose:</CharacterDetail> {character.purpose}
                <br />
                <CharacterDetail>Relevance:</CharacterDetail>{" "}
                {character.relevance}
                <br />
                <CharacterDetail>Role:</CharacterDetail> {character.role}
                <br />
                <CharacterDetail>Script Name:</CharacterDetail>{" "}
                {character.scriptName}
                <br />
                <CharacterDetail>Setting Tropes:</CharacterDetail>{" "}
                {character.settingTropes}
                <br />
                <CharacterDetail>Species:</CharacterDetail> {character.species}
                <br />
                <CharacterDetail>Type:</CharacterDetail> {character.type}
                <br />
                <CharacterDetail>Visual Description:</CharacterDetail>{" "}
                {character.visualDescription}
                <br />
                <CharacterDetail>Key Relationships:</CharacterDetail>
                <br />
                {character.relationalDynamics.keyRelationships.map(
                  (relationship, relIndex: number) => (
                    <span key={relIndex}>
                      <CharacterDetail>- Script name:</CharacterDetail>{" "}
                      {relationship.scriptName}{" "}
                      <CharacterDetail>Relationship:</CharacterDetail>{" "}
                      {relationship.relationship}
                      <br />
                    </span>
                  ),
                )}
                <CharacterDetail>Reputation:</CharacterDetail>{" "}
                {character.relationalDynamics.reputation}
                <br />
              </Typography>
            </Box>
          </CustomAccordion>
        ))
      )}
    </SectionWrapper>
  );
};

export default CharactersStage;
