import axios from "axios";

interface StartCharacterBlueprintGenerationType {
  storyId: string;
  setAlert: (alert: string) => void;
  token: string;
}

const startCharacterBlueprintGeneration = async ({
  token,
  storyId,
  setAlert,
}: StartCharacterBlueprintGenerationType) => {
  try {
    console.log("Start character blueprints generation!");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/start-character-blueprint-generation`,
      { storyId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );
    console.log("Start blueprint gen response: ", response);

    return response;
  } catch (error) {
    console.log(error);
    setAlert("Failed to start character image generation.");
  }
};

export default startCharacterBlueprintGeneration;
