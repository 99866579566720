import { useRef, useEffect, useCallback } from "react";
import { CharacterImage } from "../types/characterTypes";

const useCharacterParallax = (
  characters: CharacterImage[],
  isPlaying: boolean,
) => {
  const characterRefs = useRef<(HTMLDivElement | null)[]>([null, null]);

  const animationFrameId = useRef<number | null>(null);
  const characterParallaxConfig = {
    amplitude: [20, 18],
    factor: [0.32, 0.28],
    scale: 0.5,
  };

  const animateCharacters = useCallback(() => {
    const timestamp = Date.now();

    characters.forEach((character, index) => {
      const ref = characterRefs.current[index];

      if (ref && character.hasImage) {
        const x =
          Math.sin(timestamp / (4000 + index * 200) + index) *
          characterParallaxConfig.factor[index];
        const y =
          Math.cos(timestamp / (6000 + index * 200) + index) *
          characterParallaxConfig.factor[index];

        const amplitudeValue = characterParallaxConfig.amplitude[index];

        ref.style.transform = `
          translate(-50%, -50%) 
          translate(${x * amplitudeValue}px, ${y * amplitudeValue}px) 
          scale(${characterParallaxConfig.scale})
        `;
      }
    });

    animationFrameId.current = requestAnimationFrame(animateCharacters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characters]);

  useEffect(() => {
    if (isPlaying) {
      const animationFrameId = requestAnimationFrame(animateCharacters);
      return () => cancelAnimationFrame(animationFrameId);
    }
  }, [isPlaying, animateCharacters]);

  return characterRefs;
};

export default useCharacterParallax;
