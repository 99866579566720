import axios from "axios";

interface CheckAudioGenerationStateType {
  token: string;
  storyId: string;
  audioType: "soundscape" | "music";
  queryUrl: string;
}

const checkAudioGenerationState = async ({
  token,
  storyId,
  audioType,
  queryUrl,
}: CheckAudioGenerationStateType) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/check-audio-generation-state`,
      {
        storyId,
        audioType: audioType,
        fileQueryUrl: queryUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );

    return response;
  } catch (error) {
    console.error(`Error checking ${audioType} generation status:`, error);
  }
};

export default checkAudioGenerationState;
