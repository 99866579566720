import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import ViewerBox from "../components/ViewerBox";
import PageLayout from "../components/layout/PageLayout";

const StoryPage = () => {
  const location = useLocation();
  const { title, storyId, createdAt } = location.state || {};

  return (
    <PageLayout title="Library" subtitle={title}>
      <Box sx={{ marginTop: "16px", marginLeft: "16px", marginRight: "24px" }}>
        <ViewerBox
          storyId={storyId}
          title={title}
          createdAt={createdAt}
          bio="Placeholder Bio"
          genre="Placeholder Genre"
          // backgroundImage={story.backgroundImage}
        />
      </Box>
    </PageLayout>
  );
};

export default StoryPage;
