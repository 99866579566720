interface StoryBeatDetailProps {
  children: React.ReactNode;
}

const StoryBeatDetail = ({ children }: StoryBeatDetailProps) => (
  <span style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}>
    {children}
  </span>
);

export default StoryBeatDetail;
