import { ResponseSubgraphType } from "../pages/NewStory";

type GetSubsections = {
  subgraphDetails: ResponseSubgraphType[];
  currentSubgraph: string;
};

const getSubsections = ({
  subgraphDetails,
  currentSubgraph,
}: GetSubsections) => {
  const currentSubgraphDetails = subgraphDetails.find(
    (item) => item.section === currentSubgraph,
  );

  if (!currentSubgraphDetails) {
    console.error(`No details found for subgraph: ${currentSubgraph}`);
    return;
  }

  const subsections = currentSubgraphDetails.subsections;

  return subsections;
};

export default getSubsections;
