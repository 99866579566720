import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";
import SaveButton from "./SaveButton";

type ViewerBoxProps = {
  storyId: string;
  title: string;
  createdAt: string;
  bio: string;
  genre: string;
  // backgroundImage: string;
};

const ViewerBox = ({
  storyId,
  title,
  bio,
  createdAt,
  genre,
  // backgroundImage,
}: ViewerBoxProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const isTablet = useMediaQuery(theme.breakpoints.between("mobile", "tablet"));

  console.log(createdAt);
  console.log(navigate);
  console.log(genre);
  return (
    <Box
      sx={{
        position: "relative",
        width: "calc(100% - 32px)",
        height: isMobile ? "300px" : isTablet ? "400px" : "600px",
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        borderRadius: "8px",
        overflow: "hidden",
        marginTop: "16px",
        marginLeft: "16px",
        marginRight: "16px",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        color: "white",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to right, black, transparent)",
          borderRadius: "8px",
          border: "3px solid black",
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : "40%",
        }}
      >
        <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
          {title}
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"} paragraph>
          {bio}
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"} paragraph>
          {genre}
        </Typography>

        <Box sx={{ display: "flex", gap: "16px" }}>
          <SaveButton />
          <Button
            variant="contained"
            sx={{
              bgcolor: "#28D8A9",
              color: "black",
              "&:hover": {
                bgcolor: "#5FE692",
                color: "white",
              },
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => navigate(`/story-viewer/${storyId}`)}
            endIcon={<PlayArrowIcon />}
          >
            Play
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewerBox;
