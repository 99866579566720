import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSignUp } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

type RegisterProps = {
  setAuthType: (value: "login" | "register") => void;
};

const Register = ({ setAuthType }: RegisterProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { signUp, isLoaded, setActive } = useSignUp();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    setIsLoading(true);
    setError("");

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      setIsLoading(false);
      return;
    }

    if (!email.includes("@")) {
      setError("Please enter a valid email address");
      setIsLoading(false);
      return;
    }

    try {
      const result = await signUp.create({
        emailAddress: email,
        password,
      });

      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });
        navigate("/library");
      } else {
        setError("Registration incomplete. Please try again.");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error("Error during registration:", err);
      if (err.errors && err.errors.length > 0) {
        setError(err.errors[0].message);
      } else {
        setError(
          "An unexpected error occurred during registration. Please try again.",
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isLoaded) {
    return <CircularProgress />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: "360px" }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ marginBottom: "16px", textAlign: "center" }}
      >
        Register
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ marginTop: "24px", marginBottom: "16px" }}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Register"}
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Typography variant="body2">Already registered? </Typography>
        <Button
          color="primary"
          onClick={() => setAuthType("login")}
          sx={{
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
              color: (theme) => theme.palette.primary.light,
            },
            "&:focus": {
              outline: "none",
            },
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Register;
