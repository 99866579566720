import { useState } from "react";
import { CharacterImage, Emotion } from "../types/characterTypes";

export const useCharacters = () => {
  const [characters, setCharacters] = useState<CharacterImage[]>([]);
  const [unavailableCharacters, setUnavailableCharacters] = useState<
    Set<string>
  >(new Set());

  const createNewCharacter = (
    characterName: string,
    characterUrl: string,
    expression: Emotion,
    existingCharacters: CharacterImage[],
  ): CharacterImage => {
    const positions = [30, 65];
    const usedPositions = existingCharacters.map((c) => c.position);

    // Determine the available positions
    const availablePositions = positions.filter(
      (pos) => !usedPositions.includes(pos),
    );

    // Choose the first available position, prioritizing 30 over 65
    const availablePosition =
      availablePositions.length > 0 ? availablePositions[0] : null;
    if (availablePosition === null) {
      throw new Error("No available positions for new character");
    }

    return {
      name: characterName,
      isVisible: false,
      expression,
      url: characterUrl,
      position: availablePosition,
      hasBeenVisibleBefore: true,
      hasImage: true,
      isSpeaking: false,
    };
  };

  const updateCharactersWithImage = (
    characterName: string,
    characterUrl: string,
    expression: Emotion,
  ) => {
    const existingCharacter = characters.find((c) => c.name === characterName);

    if (existingCharacter) {
      if (
        existingCharacter.expression !== expression ||
        existingCharacter.url !== characterUrl
      ) {
        setCharacters((prevCharacters) =>
          prevCharacters.map((c) =>
            c.name === characterName ? { ...c, isVisible: false } : { ...c },
          ),
        );

        setTimeout(() => {
          setCharacters((prevCharacters) =>
            prevCharacters.map((c) =>
              c.name === characterName
                ? {
                    ...c,
                    expression,
                    url: characterUrl,
                    isSpeaking: true,
                    isVisible: false,
                  }
                : { ...c, isSpeaking: false },
            ),
          );

          setTimeout(() => {
            setCharacters((prevCharacters2) =>
              prevCharacters2.map((c) =>
                c.name === characterName ? { ...c, isVisible: true } : c,
              ),
            );
          }, 300);
        }, 300);
      } else {
        setCharacters((prevCharacters) =>
          prevCharacters.map((c) =>
            c.name === characterName
              ? { ...c, isSpeaking: true }
              : { ...c, isSpeaking: false },
          ),
        );
      }
    } else {
      if (unavailableCharacters.has(characterName)) {
        setCharacters((prevCharacters) =>
          prevCharacters.map((c) => ({ ...c, isSpeaking: false })),
        );
        return;
      }

      if (characters.length >= 2) {
        console.log("Two characters already present, removing the first one");
        characters.shift();
      }

      const newCharacter = createNewCharacter(
        characterName,
        characterUrl,
        expression,
        characters,
      );

      setCharacters((prevCharacters) => {
        const updated = [...prevCharacters, newCharacter];
        return updated.map((c) =>
          c.name === characterName
            ? {
                ...c,
                isSpeaking: true,
              }
            : { ...c, isSpeaking: false },
        );
      });

      setTimeout(() => {
        setCharacters((prevCharacters) =>
          prevCharacters.map((c) =>
            c.name === characterName ? { ...c, isVisible: true } : c,
          ),
        );
      }, 300);
    }
  };

  return {
    characters,
    setCharacters,
    setUnavailableCharacters,
    updateCharactersWithImage,
  };
};
