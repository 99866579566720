import { useRef, useEffect, useCallback } from "react";

type ParallaxLayer = "background" | "foreground" | "storyboardFrame";

interface ParallaxLayerConfig {
  factor: number;
  amplitude: number;
  scale: number;
}

interface ParallaxConfig {
  [key: string]: ParallaxLayerConfig;
}

const useSceneParallaxAnimation = (isPlaying: boolean) => {
  const refs = {
    background: useRef<HTMLVideoElement | HTMLImageElement>(null),
    foreground: useRef<HTMLImageElement>(null),
    storyboardFrame: useRef<HTMLImageElement>(null),
  };

  const isPlayingRef = useRef(isPlaying);

  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  const parallaxConfig: ParallaxConfig = {
    background: { factor: 0.08, amplitude: 10, scale: 1.1 },
    foreground: { factor: 0.2, amplitude: 12, scale: 0.9 },
    storyboardFrame: { factor: 0.16, amplitude: 16, scale: 0.85 },
  };

  const animateParallax = useCallback(() => {
    const timestamp = Date.now();

    Object.entries(parallaxConfig).forEach(([key, config]) => {
      const ref = refs[key as ParallaxLayer].current;
      if (ref) {
        const x = isPlayingRef.current
          ? Math.sin(timestamp / 3000) * config.factor * config.amplitude
          : 0;
        const y = isPlayingRef.current
          ? Math.cos(timestamp / 4000) * config.factor * config.amplitude
          : 0;

        ref.style.transform = `
          translate(-50%, -50%)
          translate(${x}px, ${y}px)
          scale(${config.scale})
        `;
      }
    });

    requestAnimationFrame(animateParallax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const animationFrameId = requestAnimationFrame(animateParallax);
    return () => cancelAnimationFrame(animationFrameId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return refs;
};

export default useSceneParallaxAnimation;
