import { useEffect, useRef, useState } from "react";
import axios from "axios";

import { useAuth } from "@clerk/clerk-react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AlertMessage from "../components/AlertMessage";
import StoryForm from "../components/StoryForm";
import { QRCodeSVG } from "qrcode.react";
import startMusicAndSoundscape from "../utils/startMusicAndSoundscape";
import startPoseAndExpressionGeneration from "../utils/startPoseAndExpressionGeneration";
import startSceneImagesGeneration from "../utils/startSceneImagesGeneration";
import startCharacterBlueprintGeneration from "../utils/startCharacterBlueprintGeneration";
import checkAudioGenerationState from "../utils/checkAudioGenerationState";
import uploadAudio from "../utils/uploadAudio";
import DoneIcon from "@mui/icons-material/Done";

type SubgraphDetails = {
  section: string;
  subsections: Record<string, string>[];
  outputs: Record<string, string>;
}[];

const AutomatedStoryGenerator = () => {
  const { getToken } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const [currentSubsection, setCurrentSubsection] = useState("");
  const [title, setTitle] = useState("");
  const [storyId, setStoryId] = useState("");
  const [subgraphDetails, setSubgraphDetails] = useState<SubgraphDetails>([]);
  const [showContinueButton, setShowContinueButton] = useState(false);

  const [alert, setAlert] = useState("");

  const [showInitialSetupLoading, setShowInitialSetupLoading] = useState(false);
  const [soundscapeGenerationInProgress, setSoundscapeGenerationInProgress] =
    useState(false);
  const [musicGenerationInProgress, setMusicGenerationInProgress] =
    useState(false);
  const [characterImagesInProgress, setCharacterImagesInProgress] =
    useState(false);
  const [sceneImagesInProgress, setSceneImagesInProgress] = useState(false);

  const [musicGenerationReady, setMusicGenerationReady] = useState(false);
  const [soundscapeGenerationReady, setSoundscapeGenerationReady] =
    useState(false);
  const [characterBlueprintsReady, setCharacterBlueprintsReady] =
    useState(false);
  const [characterImagesReady, setCharacterImagesReady] = useState(false);
  const [sceneImagesReady, setSceneImagesReady] = useState(false);
  const [isStoryGenerationReady, setIsStoryGenerationReady] = useState(false);

  const [musicGenerationQueryURL, setMusicGenerationQueryURL] = useState("");
  const [soundscapeGenerationQueryURL, setSoundscapeGenerationQueryURL] =
    useState("");
  const statusPollingRef = useRef<NodeJS.Timeout | null>(null);

  const characterBlueprintIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const characterImagesIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const sceneImagesIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const musicIntervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const soundscapeIntervalIdRef = useRef<NodeJS.Timeout | null>(null);
  // const statusPollingRef = useRef<NodeJS.Timeout | null>(null);
  const isMounted = useRef(true);

  const musicPollAttemptsRef = useRef(0);
  const soundscapePollAttemptsRef = useRef(0);

  const MAX_POLL_ATTEMPTS = 15;

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (characterBlueprintsReady) {
      const startCharacterImages = async () => {
        console.log("Start character images!");
        const token = (await getToken()) as string;
        startPoseAndExpressionGeneration({ storyId, setAlert, token });
      };

      startCharacterImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterBlueprintsReady]);

  // ***** Cleanup function *****
  useEffect(() => {
    return () => {
      if (musicIntervalIdRef.current) clearInterval(musicIntervalIdRef.current);
      if (soundscapeIntervalIdRef.current)
        clearInterval(soundscapeIntervalIdRef.current);
      if (characterBlueprintIntervalRef.current)
        clearInterval(characterBlueprintIntervalRef.current);
      if (characterImagesIntervalRef.current)
        clearInterval(characterImagesIntervalRef.current);
      if (sceneImagesIntervalRef.current)
        clearInterval(sceneImagesIntervalRef.current);
      if (statusPollingRef.current) clearInterval(statusPollingRef.current);
    };
  }, []);

  // ***** Music Polling *****
  useEffect(() => {
    const fetchMusicGenerationState = async () => {
      console.log("In music polling");
      try {
        const stateToken = (await getToken()) as string;

        const response = await checkAudioGenerationState({
          token: stateToken,
          storyId,
          audioType: "music",
          queryUrl: soundscapeGenerationQueryURL,
        });

        if (response?.data.status === "DONE" && response.data.file_url) {
          // Stop the polling immediately before proceeding
          if (musicIntervalIdRef.current) {
            console.log("Music file ready: Clearing the interval");
            clearInterval(musicIntervalIdRef.current);
            musicIntervalIdRef.current = null;
          }
          const uploadToken = (await getToken()) as string;
          await uploadAudio({
            token: uploadToken,
            storyId,
            audioType: "music",
            fileUrl: response.data.file_url,
          });

          setMusicGenerationReady(true);
          console.log("Music generation complete");
          return;
        }
      } catch (error) {
        console.error("Error checking music generation status:", error);
      }
    };

    const clearPolling = () => {
      if (musicIntervalIdRef.current) {
        clearInterval(musicIntervalIdRef.current);
        musicIntervalIdRef.current = null;
      }
    };

    const startPolling = () => {
      console.log("Starting polling for music generation");
      musicPollAttemptsRef.current = 0;

      musicIntervalIdRef.current = setInterval(() => {
        if (musicPollAttemptsRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Music maximum polling attempts reached. Stopping polling.",
          );
          clearPolling();
        } else {
          musicPollAttemptsRef.current += 1;
          console.log(`Music polling attempt ${musicPollAttemptsRef.current}`);
          fetchMusicGenerationState();
        }
      }, 60000);
    };

    // Main logic
    if (musicGenerationQueryURL) {
      if (!musicIntervalIdRef.current) startPolling();
    } else {
      clearPolling();
    }

    return clearPolling;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [musicGenerationQueryURL]);

  // ***** Soundscape Polling *****
  useEffect(() => {
    const fetchSoundscapeGenerationState = async () => {
      try {
        const stateToken = (await getToken()) as string;

        const response = await checkAudioGenerationState({
          token: stateToken,
          storyId,
          audioType: "soundscape",
          queryUrl: soundscapeGenerationQueryURL,
        });

        if (response?.data.status === "DONE" && response.data.file_url) {
          // Stop the polling immediately before proceeding
          if (soundscapeIntervalIdRef.current) {
            console.log("Soundscape file ready: Clearing the interval");
            clearInterval(soundscapeIntervalIdRef.current);
            soundscapeIntervalIdRef.current = null;
          }
          const uploadToken = (await getToken()) as string;
          await uploadAudio({
            token: uploadToken,
            storyId,
            audioType: "soundscape",
            fileUrl: response.data.file_url,
          });

          setSoundscapeGenerationReady(true);
          console.log("Soundscape generation complete");
          return;
        }
      } catch (error) {
        console.error("Error checking soundscape generation status:", error);
      }
    };

    const clearPolling = () => {
      if (soundscapeIntervalIdRef.current) {
        clearInterval(soundscapeIntervalIdRef.current);
        soundscapeIntervalIdRef.current = null;
      }
    };

    const startPolling = () => {
      console.log("Starting polling for soundscape generation");
      soundscapePollAttemptsRef.current = 0;

      soundscapeIntervalIdRef.current = setInterval(() => {
        if (soundscapePollAttemptsRef.current >= MAX_POLL_ATTEMPTS) {
          console.log(
            "Soundscape maximum polling attempts reached. Stopping polling.",
          );
          clearPolling();
        } else {
          soundscapePollAttemptsRef.current += 1;
          console.log(
            `Soundscape polling attempt ${soundscapePollAttemptsRef.current}`,
          );
          fetchSoundscapeGenerationState();
        }
      }, 60000);
    };

    // Main logic
    if (soundscapeGenerationQueryURL) {
      if (!soundscapeIntervalIdRef.current) startPolling();
    } else {
      clearPolling();
    }

    return clearPolling;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundscapeGenerationQueryURL]);

  const createInitialStory = async (formData: { prompt: string }) => {
    try {
      setShowInitialSetupLoading(true);
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const {
        storyId: newStoryId,
        title: newTitle,
        subgraphs,
      } = response.data.message;
      setStoryId(newStoryId);
      setTitle(newTitle);
      setSubgraphDetails(subgraphs);
      setShowInitialSetupLoading(false);
      setShowContinueButton(true);
    } catch (error) {
      setAlert("Failed to create initial story");
      setShowInitialSetupLoading(false);
    }
  };

  const checkCharacterBlueprintsReady = async () => {
    console.log("Check character blueprints are ready!");
    try {
      const token = await getToken();
      // Polling for character images readiness
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-character-blueprints-ready`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.missing_characters?.length === 0) {
        console.log("Character blueprints are ready!");
        console.log(
          "response.data.missing_characters",
          response.data.missing_characters,
        );
        setCharacterBlueprintsReady(true);
        return true;
      }

      // Store interval ID and clear previous one
      if (characterBlueprintIntervalRef.current)
        clearInterval(characterBlueprintIntervalRef.current);

      characterBlueprintIntervalRef.current = setInterval(() => {
        console.log("set polling for blueprints");
        checkCharacterBlueprintsReady();
      }, 15000);
    } catch (error) {
      setAlert("Failed to check character blueprints readiness");
    }
  };

  const checkCharacterImagesReady = async () => {
    console.log("Check character images are ready!");
    try {
      const token = await getToken();
      // Polling for character images readiness
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-character-images-ready`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.allCharacterImagesAreReady) {
        console.log("Character images are ready!");

        setCharacterImagesReady(true);
        return;
      }

      if (characterImagesIntervalRef.current)
        clearInterval(characterImagesIntervalRef.current);

      characterImagesIntervalRef.current = setInterval(() => {
        console.log("set polling for char images");

        checkCharacterImagesReady();
      }, 15000);
    } catch (error) {
      setAlert("Failed to check character images readiness");
    }
  };

  const checkSceneImagesReady = async () => {
    console.log("Check scene images are ready!");
    try {
      const token = await getToken();
      // Polling for character images readiness
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-scene-images-ready`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.allSceneImagesAreReady) {
        console.log("Scene images are ready!");

        setSceneImagesReady(true);
        return true;
      }

      if (sceneImagesIntervalRef.current)
        clearInterval(sceneImagesIntervalRef.current);

      sceneImagesIntervalRef.current = setInterval(() => {
        console.log("set polling for scene images");
        checkSceneImagesReady();
      }, 15000);
    } catch (error) {
      setAlert("Failed to check scene images readiness");
    }
  };

  const startProcessing = async () => {
    console.log("Start processing!");
    setIsProcessing(true);
    setShowContinueButton(false);
    await processNextSection(0, 0);
  };

  const processNextSection = async (
    sectionIndex: number,
    subsectionIndex: number,
  ) => {
    console.log("isMounted.current in section", isMounted.current);
    if (!isMounted.current) return;
    console.log(`Process next section!`);
    if (sectionIndex >= subgraphDetails.length) {
      // setIsProcessing(false);
      setIsStoryGenerationReady(true);
      return;
    }

    const currentSectionData = subgraphDetails[sectionIndex];
    const subsections = currentSectionData.subsections;

    if (subsectionIndex >= subsections.length) {
      // Move to next section
      await processNextSection(sectionIndex + 1, 0);
      return;
    }

    const subsectionData = subsections[subsectionIndex];
    const subsectionKey = Object.keys(subsectionData)[0];
    const graphName = subsectionData[subsectionKey];

    setCurrentSection(currentSectionData.section);
    setCurrentSubsection(subsectionKey);

    try {
      const token = await getToken();

      if (
        currentSectionData.section === "Scenes" &&
        subsectionKey === "StoryBeatsEvents"
      ) {
        console.log("Start character blueprint generation!");

        const token = (await getToken()) as string;

        const response = await startCharacterBlueprintGeneration({
          token,
          storyId,
          setAlert,
        });

        if (response) {
          setCharacterImagesInProgress(true);
          // After starting blueprint generation, start polling for character images readiness
          checkCharacterBlueprintsReady();
        }
      }

      if (
        currentSectionData.section === "Script" &&
        subsectionKey === "ScriptLinesNoEmotion"
      ) {
        console.log("Start scenes image generation!");

        const token = (await getToken()) as string;
        const response = await startSceneImagesGeneration({
          storyId,
          setAlert,
          token,
        });

        if (response) {
          setSceneImagesInProgress(true);
        }
        console.log("Start scene images generation response: ", response);
      }

      if (
        currentSectionData.section === "Script" &&
        subsectionKey === "ScriptHuman"
      ) {
        console.log("Start music and soundscape generation!");

        const token = (await getToken()) as string;

        // Send a post request to start scene image generation
        checkCharacterImagesReady();
        checkSceneImagesReady();

        const audioResponse = await startMusicAndSoundscape({
          token,
          storyId,
          setAlert,
        });
        setSoundscapeGenerationInProgress(true);
        setMusicGenerationInProgress(true);
        if (audioResponse?.musicResponse.status === 200) {
          const fileQueryUrl = audioResponse.musicResponse.data.file_query_url;
          setMusicGenerationQueryURL(fileQueryUrl);
        }

        if (audioResponse?.soundscapeResponse.status === 200) {
          const fileQueryUrl = audioResponse.soundscapeResponse.data.file_url;

          setSoundscapeGenerationQueryURL(fileQueryUrl);
        }
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/run-subgraph`,
        {
          storyId,
          mainSection: currentSectionData.section,
          subsection: subsectionKey,
          graph: graphName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // Start polling
      pollStatus(
        currentSectionData.section,
        subsectionKey,
        sectionIndex,
        subsectionIndex,
      );
    } catch (error) {
      setAlert(
        `Failed to process ${currentSectionData.section} - ${subsectionKey}`,
      );
      setIsProcessing(false);
    }
  };

  const pollStatus = async (
    section: string,
    subsection: string,
    sectionIndex: number,
    subsectionIndex: number,
  ) => {
    console.log("Polling states of: ", subsection);
    console.log("isMounted.current", isMounted.current);
    if (!isMounted.current) return;
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stories/${storyId}/section/${section}?subsection=${subsection}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { subsection_status, main_section_status } = response.data;

      if (subsection_status === "failed" || main_section_status === "failed") {
        setAlert(`Failed to process ${section} - ${subsection}`);
        setIsProcessing(false);
        return;
      }

      if (subsection_status === "complete") {
        // Move to next subsection or section
        await processNextSection(sectionIndex, subsectionIndex + 1);
        return;
      }

      if (statusPollingRef.current) clearInterval(statusPollingRef.current);

      // Continue polling if still in progress
      statusPollingRef.current = setInterval(
        () => pollStatus(section, subsection, sectionIndex, subsectionIndex),
        15000,
      );
    } catch (error) {
      setAlert(`Failed to check status for ${section} - ${subsection}`);
      setIsProcessing(false);
    }
  };

  const totalSections = subgraphDetails.length;
  const currentSectionIndex = subgraphDetails.findIndex(
    (s) => s.section === currentSection,
  );
  const progress =
    currentSectionIndex >= 0
      ? Math.round((currentSectionIndex / totalSections) * 100)
      : 0;

  return (
    <Box className="flex flex-col items-center justify-center min-h-screen p-4">
      {!currentSubsection && title && storyId && (
        <QRCodeSVG
          value={`https://web.staging.chaws.xyz/#/story-viewer/${storyId}`}
        />
      )}
      {showInitialSetupLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingLeft: "16px",
          }}
        >
          <Typography sx={{ marginBottom: "20px", color: "#fff" }}>
            Initial Setup Loading...
          </Typography>
          <CircularProgress size="70px" />
        </Box>
      )}
      {!showInitialSetupLoading && !alert && !showContinueButton && !title && (
        <Box
          sx={{
            width: "100%",
            marginTop: "80px",
            paddingLeft: { xs: "16px", sm: "240px" },
          }}
        >
          <StoryForm handleSubmit={createInitialStory} />
        </Box>
      )}
      {showContinueButton && title && (
        <Box className="text-center space-y-4">
          <Typography className="text-2xl font-bold mb-8">
            Initial Title: {title}
          </Typography>
          <Button onClick={startProcessing} className="mb-4">
            Continue Generation
          </Button>
        </Box>
      )}
      {isProcessing && (
        <Box className="text-center space-y-4">
          <Typography className="text-xl font-semibold">
            {title && `Generating: ${title}`}
          </Typography>
          <>
            <Typography className="text-lg">
              Processing: {currentSection} - {currentSubsection}
              {/* Processing: {currentSection} Section */}
            </Typography>
            <Typography className="text-sm text-gray-500">
              Story:
              {isStoryGenerationReady ? (
                <DoneIcon />
              ) : (
                <>
                  progress - {progress}%{" "}
                  {progress !== 100 && <CircularProgress size="20px" />}
                </>
              )}
            </Typography>

            <Box>
              Soundscape:{" "}
              {(soundscapeGenerationInProgress && <CircularProgress />) ||
                (soundscapeGenerationReady && <DoneIcon />)}
            </Box>
            <Box>
              Music:{" "}
              {(musicGenerationInProgress && <CircularProgress />) ||
                (musicGenerationReady && <DoneIcon />)}
            </Box>
            <Box>
              Scene Images:{" "}
              {(sceneImagesInProgress && <CircularProgress />) ||
                (sceneImagesReady && <DoneIcon />)}
            </Box>
            <Box>
              Character Images:{" "}
              {(characterImagesInProgress && <CircularProgress />) ||
                (characterImagesReady && <DoneIcon />)}
            </Box>
          </>
        </Box>
      )}

      {alert && <AlertMessage severity="error" message={alert} />}
    </Box>
  );
};

export default AutomatedStoryGenerator;
