import { createContext, ReactNode, useContext, useState } from "react";

type StoryGenerationState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { section: string; value: any }[];
};

const StoryGenerationContext = createContext<{
  storyGenerationState: StoryGenerationState;
  updateStoryGenerationState: (newSection: Record<string, unknown>) => void;
}>({
  storyGenerationState: { data: [] },
  updateStoryGenerationState: () => {},
});

export const StoryGenerationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [storyGenerationState, setStoryGenerationState] =
    useState<StoryGenerationState>({ data: [] });

  const updateStoryGenerationState = (newSection: Record<string, unknown>) => {
    const sectionKey = Object.keys(newSection)[0];
    const sectionValue = newSection[sectionKey];

    setStoryGenerationState((prevState) => {
      const sectionExists = prevState.data.some(
        (section) => section.section === sectionKey,
      );

      const updatedData = sectionExists
        ? prevState.data.map((section) =>
            section.section === sectionKey
              ? { ...section, value: sectionValue }
              : section,
          )
        : [...prevState.data, { section: sectionKey, value: sectionValue }];

      return { ...prevState, data: updatedData };
    });
  };
  return (
    <StoryGenerationContext.Provider
      value={{ storyGenerationState, updateStoryGenerationState }}
    >
      {children}
    </StoryGenerationContext.Provider>
  );
};

export const useStoryGenerationContext = () =>
  useContext(StoryGenerationContext);
