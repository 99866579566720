import { Alert, AlertColor, AlertPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { useEffect, useState } from "react";

type AlertMessageProps = {
  message: string;
  severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
};

const AlertMessage = ({ message, severity }: AlertMessageProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Alert variant="filled" severity={severity}>
      {message}
    </Alert>
  );
};

export default AlertMessage;
