import React, { useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";

import StoryForm from "../components/StoryForm";
import ProgressBar from "../components/story/ProgressBar";
import ScriptStage from "../components/story/stages/ScriptStage";
import StoryBeatsStage from "../components/story/stages/StoryBeatsStage";
import GenresStage from "../components/story/stages/GenresStage";
import SettingStage from "../components/story/stages/SettingStage";
import TitleStage from "../components/story/stages/TitleStage";
import CharacterStage from "../components/story/stages/CharacterStage";
import ScenesStage from "../components/story/stages/ScenesStage";

import BottomBarStoryGeneration from "../components/story/bottomBar/BottomBarStoryGeneration";
import AlertMessage from "../components/AlertMessage";
import { useStoryGenerationContext } from "../context/StoryGenerationContext";

import WarningIcon from "@mui/icons-material/Warning";
import getSubsections from "../utils/getSubsections";

interface Subsection {
  [key: string]: string;
}

export type ResponseSubgraphType = {
  section: string;
  // TODO: Look at this unknown type thing
  outputs: Record<string, unknown>;
  subsections: Subsection[];
};

type NewStoryStateProps = {
  currentStep: number;
  sections: string[];
  subgraphDetails: ResponseSubgraphType[];
  nextSubgraphIndex: number;
  storyId: string;
  showActionButton: boolean;
  showInitialSetupLoading: boolean;
  showStoryForm: boolean;
};

const stageComponentMap: Record<string, React.ElementType> = {
  Title: TitleStage,
  Genres: GenresStage,
  Setting: SettingStage,
  Characters: CharacterStage,
  StoryBeats: StoryBeatsStage,
  Scenes: ScenesStage,
  Script: ScriptStage,
};

const NewStory = () => {
  const { getToken } = useAuth();
  const { updateStoryGenerationState } = useStoryGenerationContext();

  const [state, setState] = useState<NewStoryStateProps>({
    currentStep: -1,
    showStoryForm: true,
    sections: ["Title"],
    subgraphDetails: [],
    nextSubgraphIndex: 1,
    storyId: "",
    showActionButton: false,
    showInitialSetupLoading: false,
  });

  const [alert, setAlert] = useState("");
  const [failedSection, setFailedSection] = useState({
    mainSection: "",
    subsection: "",
    graphName: "",
    currentIndex: 0,
  });
  const {
    currentStep,
    showStoryForm,
    sections,
    showActionButton,
    nextSubgraphIndex,
    subgraphDetails,
  } = state;

  const currentSubgraph = sections[currentStep];

  const updateState = (newState: Partial<NewStoryStateProps>) =>
    setState((prevState) => ({ ...prevState, ...newState }));

  const createStory = async (formData: { prompt: string }) => {
    setState((prevState) => ({
      ...prevState,
      showInitialSetupLoading: true,
    }));

    try {
      const token = await getToken();
      updateState({ currentStep: 0, showStoryForm: false });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );
      const {
        subgraphs: subgraphData,
        storyId: newStoryId,
        title,
      } = response.data.message;

      setState((prevState) => ({
        ...prevState,
        showInitialSetupLoading: true,
      }));

      updateStoryGenerationState({ Title: title });

      updateState({
        sections: [
          ...state.sections,
          ...subgraphData.map(
            (subgraph: ResponseSubgraphType) => subgraph.section,
          ),
        ],
        subgraphDetails: subgraphData,
        storyId: newStoryId,
        showActionButton: true,
        showStoryForm: false,
        showInitialSetupLoading: false,
      });
    } catch (error) {
      updateState({ showStoryForm: true, showInitialSetupLoading: false });
      console.error("Error creating story:", error);
      setAlert("Failed to create story!");
    }
  };

  const pollForOutcome = async (
    section: string,
    subsection: string,
    currentIndex: number,
  ) => {
    return new Promise<void>((resolve, reject) => {
      const intervalId = setInterval(async () => {
        try {
          const token = await getToken();
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/stories/${state.storyId}/section/${section}?subsection=${subsection}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            },
          );

          const {
            subsection_status: subsectionStatus,
            main_section_status: mainSectionStatus,
            message,
            content,
          } = response.data;

          if (subsectionStatus === "failed" || mainSectionStatus === "failed") {
            clearInterval(intervalId);
            console.error(`Polling error for section (${section}): ${message}`);
            setAlert(`Error while running ${mainSectionStatus}!`);
            reject(new Error(message));
            return;
          }

          if (
            subsectionStatus === "complete" &&
            mainSectionStatus === "complete"
          ) {
            clearInterval(intervalId);
            updateStoryGenerationState(content);
            updateState({
              nextSubgraphIndex: state.nextSubgraphIndex + 1,
              showActionButton: true,
            });
            resolve();
          } else if (
            subsectionStatus === "complete" &&
            mainSectionStatus === "inProgress"
          ) {
            clearInterval(intervalId);
            await processSubsectionsSequentially(currentIndex + 1);
            resolve();
          } else if (
            subsectionStatus === "inProgress" &&
            mainSectionStatus === "inProgress"
          ) {
            console.log("Polling again for subsection", subsection);
          } else {
            clearInterval(intervalId);
            console.error("Unexpected polling state", response.data);
            reject(new Error("Unexpected polling state"));
            setAlert(`Unexpected error while running ${mainSectionStatus}!`);
          }
        } catch (error) {
          setAlert("Unexpected error while polling for subgraph!");
          console.error(`Polling error for section (${section}):`, error);
          clearInterval(intervalId);
          reject(error);
        }
      }, 30000);
    });
  };

  const processSubsectionsSequentially = async (currentIndex: number) => {
    const currentSubgraph = state.sections[state.nextSubgraphIndex];
    // TODO: Rename this to get Subsections of Sections
    const subsections =
      getSubsections({
        subgraphDetails,
        currentSubgraph,
      }) || [];

    const currentSubsectionObject = subsections[currentIndex];
    const subsectionKey = Object.keys(currentSubsectionObject)[0];
    const graphName = currentSubsectionObject[subsectionKey];

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/run-subgraph`,
        {
          storyId: state.storyId,
          mainSection: currentSubgraph,
          subsection: subsectionKey,
          graph: graphName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        await pollForOutcome(currentSubgraph, subsectionKey, currentIndex);
      }
    } catch (error) {
      console.error(`Error processing subsection (${graphName}):`, error);
      setFailedSection({
        mainSection: currentSubgraph,
        subsection: subsectionKey,
        graphName,
        currentIndex,
      });
      setAlert("Failed to process subsection!");
    }
  };

  const handleNext = async () => {
    try {
      updateState({
        showActionButton: false,
        currentStep: state.currentStep + 1,
      });

      if (nextSubgraphIndex >= sections.length) {
        updateState({ showActionButton: false });
        return;
      }

      // Start processing the first subsection
      await processSubsectionsSequentially(0);
    } catch (error) {
      setAlert("Something went wrong while starting next section!");
      console.error("Error in handleNext:", error);
    }
  };

  const handleRetry = async () => {
    if (!failedSection) return;

    setAlert("");
    updateState({ showActionButton: false });

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/run-subgraph`,
        {
          storyId: state.storyId,
          mainSection: failedSection.mainSection,
          subsection: failedSection.subsection,
          graph: failedSection.graphName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        await pollForOutcome(
          failedSection.mainSection,
          failedSection.subsection,
          failedSection.currentIndex,
        );
        // Clear the failed section after successful retry
        setFailedSection({
          mainSection: "",
          subsection: "",
          graphName: "",
          currentIndex: 0,
        });
      }
    } catch (error) {
      console.error("Error in retry:", error);
      setAlert("Retry failed! Please try again.");
      updateState({ showActionButton: true });
    }
  };

  const CurrentStageComponent = stageComponentMap[currentSubgraph];

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingBottom: "120px",
        maxWidth: "1000px",
        margin: "0 auto",
        width: "100%",
        transition: "padding-left 0.3s ease",
      }}
    >
      {alert && (
        <>
          <AlertMessage severity="error" message={alert} />
          <Box sx={{ marginTop: "100px" }}>
            {currentStep !== 0 && (
              <>
                <WarningIcon sx={{ fontSize: "50px" }} />
                <Typography>There was an error running the section!</Typography>
                {failedSection && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRetry}
                    sx={{ mt: 2 }}
                  >
                    Retry Failed Section
                  </Button>
                )}
              </>
            )}
          </Box>
        </>
      )}
      <Box
        sx={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "30px",
          height: "100px",
        }}
      >
        {!alert && sections.length > 1 && (
          <ProgressBar
            steps={sections}
            currentStep={currentStep}
            stopCircularLoading={showActionButton}
          />
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {showStoryForm && (
          <Box
            sx={{
              width: "100%",
              marginTop: "80px",
              paddingLeft: { xs: "16px", sm: "240px" },
            }}
          >
            <StoryForm handleSubmit={createStory} />
          </Box>
        )}

        {state.showInitialSetupLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              paddingLeft: "16px",
            }}
          >
            <Typography sx={{ marginBottom: "20px", color: "#fff" }}>
              Initial Setup Loading...
            </Typography>
            <CircularProgress size="70px" />
          </Box>
        )}
      </Box>

      {sections.length > 1 && !alert && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
          }}
        >
          {/* FYI handleNext is being passed universally, but not used in ScriptStage!! - soz */}
          {React.createElement(CurrentStageComponent, {
            handleNext,
            storyId: state.storyId,
            setAlert,
          })}
          <Box
            sx={{
              width: "100%",
              maxWidth: "1000px",
              paddingLeft: { xs: "16px", sm: "240px" },
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-47%)",
              display: "flex",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <BottomBarStoryGeneration
              currentStep={currentStep}
              sections={sections}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NewStory;
