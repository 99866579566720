import { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import CustomAccordion from "../accordion/CustomAccordion";
import SectionWrapper from "./SectionWrapper";
import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import { Scene } from "../../../types/sceneTypes";
import { formatFieldKey } from "../../../utils/formatFieldKey";
import startPoseAndExpressionGeneration from "../../../utils/startPoseAndExpressionGeneration";
import startSceneImagesGeneration from "../../../utils/startSceneImagesGeneration";

interface ScenesStageProps {
  handleNext: () => Promise<void>;
  storyId: string;
  setAlert: (alertMsg: string) => void;
}

const ScenesStage = ({ handleNext, storyId, setAlert }: ScenesStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState } = useStoryGenerationContext();

  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scenes =
    storyGenerationState?.data?.find((state) => state.section === "Scenes")
      ?.value || [];

  const [isBlueprintPollingFinished, setIsBlueprintPollingFinished] =
    useState(false);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const pollCountRef = useRef(0);
  const MAX_POLL_ATTEMPTS = 10;
  const POLLING_INTERVAL = 40000;

  const checkCharacterBlueprints = useCallback(async () => {
    try {
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-character-blueprints-ready`,
        { storyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      if (response.data.missing_characters?.length === 0) {
        console.log(
          "response.data.missing_characters",
          response.data.missing_characters,
        );
        setIsBlueprintPollingFinished(true);
        return true;
      }
      return false;
    } catch (error) {
      setAlert("Error while checking for character blueprints!");
      console.error("Error checking character blueprints:", error);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, storyId]);

  useEffect(() => {
    if (scenes?.length > 0) {
      setLoading(false);
    }
  }, [scenes]);

  useEffect(() => {
    if (!storyId || isBlueprintPollingFinished || scenes.length === 0) {
      return;
    }

    const startPolling = async () => {
      const isReady = await checkCharacterBlueprints();
      if (isReady) return;

      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(async () => {
          if (pollCountRef.current >= MAX_POLL_ATTEMPTS) {
            console.log("Reached maximum retry attempts. Stopping polling.");
            setIsBlueprintPollingFinished(true);
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }

          console.log(`Polling attempt ${pollCountRef.current + 1}`);
          const isReady = await checkCharacterBlueprints();
          if (isReady) {
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }
          pollCountRef.current += 1;
        }, POLLING_INTERVAL);
      }
    };

    startPolling();

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [
    storyId,
    isBlueprintPollingFinished,
    checkCharacterBlueprints,
    scenes.length,
  ]);

  return (
    <SectionWrapper
      canProceed={isBlueprintPollingFinished && !loading}
      handleNext={async () => {
        const poseToken = (await getToken()) as string;
        startPoseAndExpressionGeneration({
          storyId,
          setAlert,
          token: poseToken,
        });

        const sceneToken = (await getToken()) as string;
        startSceneImagesGeneration({
          storyId,
          setAlert,
          token: sceneToken,
        });

        await handleNext();
      }}
    >
      {scenes.length === 0 ? (
        <CustomAccordion
          title="Scenes"
          isEditingAvailable={false}
          isLoading={loading}
        >
          <Typography variant="body2">No scenes available yet.</Typography>
        </CustomAccordion>
      ) : (
        <>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            {!isBlueprintPollingFinished &&
            pollCountRef.current < MAX_POLL_ATTEMPTS ? (
              <>
                <CircularProgress size={20} />
                <Typography variant="body2" sx={{ color: "#fff", mt: 1 }}>
                  Waiting for character blueprints to be ready...
                </Typography>
              </>
            ) : pollCountRef.current >= MAX_POLL_ATTEMPTS ? (
              <Typography variant="body2" sx={{ color: "#fff" }}>
                Character blueprints took too long or failed! Please continue
                with the story generation.
              </Typography>
            ) : null}
          </Box>
          {scenes.map((scene: Scene) => (
            <CustomAccordion
              key={scene.sceneId}
              title={"Scene " + scene.sceneId}
              isEditingAvailable={false}
              isLoading={loading}
            >
              <Box
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography variant="body2">
                  <strong>{formatFieldKey("description")}:</strong>{" "}
                  {scene.description}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("location")}:</strong>{" "}
                  {scene.location}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("timeOfDay")}:</strong>{" "}
                  {scene.timeOfDay}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("characters")}:</strong>{" "}
                  {scene.characterNames?.join(", ")}
                </Typography>
                <Typography variant="body2">
                  <strong>{formatFieldKey("instructions")}:</strong>
                </Typography>
                <ol
                  style={{
                    fontSize: "14px",
                    color: "#fff",
                    marginLeft: "20px",
                  }}
                >
                  {scene.instructions.map((instruction, i) => (
                    <li key={i}>{instruction}</li>
                  ))}
                </ol>
              </Box>
            </CustomAccordion>
          ))}
        </>
      )}
    </SectionWrapper>
  );
};

export default ScenesStage;
