import { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import CustomAccordion from "../accordion/CustomAccordion";
import axios from "axios";
import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import SectionWrapper from "./SectionWrapper";
import { GenresContent } from "../../../types/genresTypes";

interface GenresStageProps {
  handleNext: () => Promise<void>;
  storyId: string;
  setAlert: (alertMsg: string) => void;
}

const GenresStage = ({ handleNext, storyId, setAlert }: GenresStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();

  const [originalContent, setOriginalContent] = useState({
    styleGenre: "",
    externalContentGenre: "",
  });
  const [editedContent, setEditedContent] = useState({
    styleGenre: "",
    externalContentGenre: "",
  });
  const [loading, setLoading] = useState({
    styleGenre: true,
    externalContentGenre: true,
  });
  const [editing, setEditing] = useState({
    styleGenre: false,
    externalContentGenre: false,
  });

  const genres: GenresContent[] = storyGenerationState?.data?.find(
    (state) => state.section === "Genres",
  )?.value;

  useEffect(() => {
    if (genres) {
      // Set initial state once genres are available
      const initialContent = {
        styleGenre: genres[0].styleGenre?.styleGenre || "",
        externalContentGenre:
          genres[0].externalContentGenre?.externalContentGenre || "",
      };
      setOriginalContent(initialContent);
      setEditedContent(initialContent);
      setLoading({ styleGenre: false, externalContentGenre: false });
    }
  }, [genres]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveGenre = async (
    genreType: "styleGenre" | "externalContentGenre",
  ) => {
    setLoading((prev) => ({ ...prev, [genreType]: true }));

    try {
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-genres`,
        { storyId, [genreType]: editedContent[genreType] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const updatedValue = response.data.updated_genre[genreType];
        setOriginalContent((prev) => ({
          ...prev,
          [genreType]: updatedValue,
        }));
        setEditedContent((prev) => ({ ...prev, [genreType]: updatedValue }));

        if (genres[0]) {
          const updatedGenres = {
            ...genres,
            0: {
              ...genres[0],
              [genreType]: {
                ...genres[0][genreType],
                [genreType]: updatedValue,
              },
            },
          };

          updateStoryGenerationState({ Genres: updatedGenres });
        }
      }
    } catch (error) {
      setAlert(`Error saving ${genreType}`);
      console.error(`Error saving ${genreType}:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [genreType]: false }));
      setEditing((prev) => ({ ...prev, [genreType]: false }));
    }
  };

  const renderGenreAccordion = (
    genreType: "styleGenre" | "externalContentGenre",
    label: string,
  ) => (
    <CustomAccordion
      title={label}
      isEditing={editing[genreType]}
      isLoading={loading[genreType]}
      toggleEdit={() => {
        // if editing is true for the genre type
        // change the edited content back to the original on close
        editing[genreType] &&
          setEditedContent((prev) => ({
            ...prev,
            [genreType]: originalContent[genreType],
          }));

        // set editing the genreType to either false or true on toggle
        setEditing((prev) => ({ ...prev, [genreType]: !prev[genreType] }));
      }}
      onSave={() => handleSaveGenre(genreType)}
      isEditingAvailable={true}
    >
      {editing[genreType] ? (
        <TextField
          label={label}
          name={genreType}
          value={editedContent[genreType]}
          onChange={handleChange}
          fullWidth
          sx={{
            input: { color: "text.primary" },
            label: { color: "text.primary" },
            backgroundColor: "background.paper",
            borderRadius: "10px",
          }}
        />
      ) : (
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          {editedContent[genreType]}
        </Typography>
      )}
    </CustomAccordion>
  );

  return (
    <SectionWrapper
      canProceed={
        !editing.styleGenre &&
        !editing.externalContentGenre &&
        !loading.styleGenre &&
        !loading.externalContentGenre
      }
      handleNext={handleNext}
    >
      {renderGenreAccordion("styleGenre", "Style Genre")}
      {renderGenreAccordion("externalContentGenre", "External Content Genre")}
    </SectionWrapper>
  );
};

export default GenresStage;
