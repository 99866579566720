import { Box, Typography } from "@mui/material";
import { ExtendedScriptLine } from "../../types/scriptTypes";

type StoryFormatProps = {
  line: ExtendedScriptLine;
  index: number;
};

const StoryFormat = ({ line, index }: StoryFormatProps) => {
  if (line.lineType === "ToneChange") {
    return null;
  }

  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      {line.lineType === "Dialogue" ? (
        <Box sx={{ textAlign: "center", width: "80%" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {line.characterName}
          </Typography>
          <Typography>{line.text}</Typography>
        </Box>
      ) : (
        <Typography
          fontStyle={line.lineType === "Action" ? "italic" : "normal"}
          sx={{
            fontStyle: line.lineType === "Action" ? "italic" : "normal",
            color: line.lineType === "Action" ? "#cccccc" : "white",
            margin: "12px 0",
            textAlign: "center",
            width: "80%",
          }}
        >
          {line.text}
        </Typography>
      )}
    </Box>
  );
};

export default StoryFormat;
