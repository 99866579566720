import { Box } from "@mui/material";
import ActionButton from "./ActionButton";

const ProfileButtons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ActionButton
        buttonText="Public Profile"
        onClick={async () => {}}
        color="green"
      />
      <ActionButton
        buttonText="My Stories"
        onClick={async () => {}}
        color="green"
      />
      <ActionButton
        buttonText="Delete Account"
        color="red"
        onClick={async () => {}}
      />
    </Box>
  );
};

export default ProfileButtons;
