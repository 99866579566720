import { useState, useEffect, useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { ExtendedScriptLineWithSceneId } from "../../types/scriptTypes";

type SubtitleDisplayProps = {
  textContent: ExtendedScriptLineWithSceneId[];
  isPlaying: boolean;
  onSceneChange: (sceneId: string) => void;
  updateCharacterImages: (line: ExtendedScriptLineWithSceneId) => void;
};

const SubtitleDisplay = ({
  textContent,
  isPlaying,
  onSceneChange,
  updateCharacterImages,
}: SubtitleDisplayProps) => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [subtitleWidth, setSubtitleWidth] = useState("50%");
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const currentLine = textContent[currentLineIndex];

  const handleSubtitleTransition = () => {
    if (!isPlaying || !currentLine) return;

    if (currentLine.lineType === "ToneChange") {
      setCurrentLineIndex((prevIndex) =>
        Math.min(prevIndex + 1, textContent.length),
      );
      return;
    }

    if (currentLine.lineType === "SceneChange") {
      onSceneChange(currentLine.sceneId);
    } else if (
      currentLine.lineType === "Dialogue" &&
      currentLine.characterName
    ) {
      updateCharacterImages(currentLine);
    }

    timeoutIdRef.current = setTimeout(() => {
      setCurrentLineIndex((prevIndex) =>
        Math.min(prevIndex + 1, textContent.length),
      );
    }, currentLine.displayTime);
  };

  useEffect(() => {
    if (isPlaying) {
      handleSubtitleTransition();
    } else {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentLineIndex]);

  useEffect(() => {
    if (!currentLine) return;

    const { text = "" } = currentLine;
    const newWidth =
      text.length <= 50 ? "70%" : text.length <= 100 ? "60%" : "75%";
    setSubtitleWidth(newWidth);
  }, [currentLine]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const subtitleFontSize = useMemo(() => {
    return `${Math.min(Math.max(windowHeight * 0.015, 10), 10)}px`;
  }, [windowHeight]);

  if (currentLineIndex >= textContent.length) return null;

  const { characterName, lineType, text } = currentLine;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "15%",
        left: "50%",
        transform: "translateX(-50%)",
        pointerEvents: "none",
        zIndex: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: subtitleWidth,
        maxWidth: "50%",
      }}
      aria-live="polite"
    >
      {/* Main Subtitle Box */}
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          color: "black",
          padding: "15px",
          border: "3px solid black",
          borderRadius: "5px",
          borderColor: "rgba(0, 0, 0, 1)",
          width: "115%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Character Name Box */}
        {characterName &&
          lineType !== "Action" &&
          characterName !== "DIRECTOR" && (
            <Box
              sx={{
                position: "absolute",
                top: "-40px",
                left: "-4px",
                backgroundColor: "rgba(0, 0, 0, 0.95)",
                color: "white",
                padding: "4px",
                border: "3px solid black",
                borderRadius: "4px",
                textAlign: "centre",
                pointerEvents: "none",
                zIndex: 5,
                width: "auto",
                minWidth: "120px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  textTransform: "uppercase",
                }}
              >
                {characterName}
              </Typography>
            </Box>
          )}

        <Typography
          sx={{
            fontSize: subtitleFontSize,
            color: "black",
            lineHeight: 1.75,
            fontStyle: lineType === "Action" ? "italic" : "normal",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default SubtitleDisplay;
