import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogWrapper from "./DialogWrapper";
import StoryBeatDetail from "../../../StoryBeatDetail";
import { StoryBeat } from "../../../../types/storyBeatTypes";

interface StoryBeatsDialogProps {
  content: StoryBeat[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const StoryBeatsDialog = ({
  content,
  isOpen,
  setIsOpen,
}: StoryBeatsDialogProps) => {
  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen} title="Story Beats">
      {content.map((storyBeat) => (
        <Box sx={{ mb: 2 }} key={storyBeat.title}>
          <Accordion
            sx={{
              backgroundColor: "rgba(102,102,102,0.4)",
              borderRadius: "4px",
              boxShadow: "none",
              "&:before": { display: "none" },
              transition: "background-color 0.3s ease",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{
                backgroundColor: "rgba(102,102,102,0.4)",
                borderRadius: "4px",
                color: "#fff",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#fff", fontSize: "1rem" }}
              >
                {storyBeat.title}
              </Typography>
            </AccordionSummary>
            <Divider sx={{ borderColor: "#ffffff" }} />
            <AccordionDetails sx={{ backgroundColor: "rgba(102,102,102,0.2)" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  textAlign: "left",
                }}
              >
                <StoryBeatDetail>Description:</StoryBeatDetail>
                {storyBeat.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </DialogWrapper>
  );
};

export default StoryBeatsDialog;
