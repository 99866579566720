import axios from "axios";

interface StartMusicAndSoundscapeGenerationType {
  token: string;
  storyId: string;
  setAlert: (alert: string) => void;
}

const startMusicAndSoundscape = async ({
  token,
  storyId,
  setAlert,
}: StartMusicAndSoundscapeGenerationType) => {
  try {
    const [musicResponse, soundscapeResponse] = await Promise.all([
      axios.post(
        `${process.env.REACT_APP_API_URL}/generate-music`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
      axios.post(
        `${process.env.REACT_APP_API_URL}/generate-soundscape`,
        { storyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    ]);
    console.log("Music and soundscape started successfully.");
    return { musicResponse, soundscapeResponse };
  } catch (error) {
    setAlert("Error while starting audio generation!");

    console.error("Error starting music or soundscape:", error);
  }
};

export default startMusicAndSoundscape;
