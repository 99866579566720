import { useEffect, useReducer, useCallback } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import CustomAccordion from "../accordion/CustomAccordion";
import axios from "axios";
import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import SectionWrapper from "./SectionWrapper";

interface TitleStageProps {
  storyId: string;
  handleNext: () => Promise<void>;
  setAlert: (alertMsg: string) => void;
}

interface State {
  editedTitle: string;
  originalTitle: string;
  isEditing: boolean;
  loading: boolean;
}

type Action =
  | { type: "SET_TITLE"; payload: string }
  | { type: "EDIT_TITLE"; payload: string }
  | { type: "TOGGLE_EDIT" }
  | { type: "SET_LOADING"; payload: boolean };

const initialState: State = {
  editedTitle: "",
  originalTitle: "",
  isEditing: false,
  loading: true,
};

const titleReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_TITLE":
      return {
        ...state,
        editedTitle: action.payload,
        originalTitle: action.payload,
        loading: false,
      };
    case "EDIT_TITLE":
      return { ...state, editedTitle: action.payload };
    case "TOGGLE_EDIT":
      return {
        ...state,
        isEditing: !state.isEditing,
        editedTitle: state.originalTitle,
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const TitleStage = ({ storyId, handleNext, setAlert }: TitleStageProps) => {
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();
  const { getToken } = useAuth();

  const [state, dispatch] = useReducer(titleReducer, initialState);
  const { editedTitle, originalTitle, isEditing, loading } = state;

  const titleValue = storyGenerationState.data.find(
    (s) => s.section === "Title",
  )?.value as string;

  useEffect(() => {
    if (titleValue) dispatch({ type: "SET_TITLE", payload: titleValue });
  }, [titleValue]);

  const toggleEdit = useCallback(() => {
    dispatch({ type: "TOGGLE_EDIT" });
  }, []);

  const handleSave = async () => {
    if (!storyId) return setAlert("Story ID missing, cannot save.");

    dispatch({ type: "SET_LOADING", payload: true });

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-title`,
        { storyId, previous_title: originalTitle, new_title: editedTitle },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const updatedTitle = response.data.updated_title;
        dispatch({ type: "SET_TITLE", payload: updatedTitle });
        toggleEdit();
        updateStoryGenerationState({ Title: updatedTitle });
      }
    } catch (error) {
      console.error("Error saving title:", error);
      setAlert("Failed to save the title. Please try again.");
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  return (
    <SectionWrapper
      canProceed={!isEditing && !!originalTitle && !loading}
      handleNext={handleNext}
    >
      <CustomAccordion
        title="Title"
        isEditing={isEditing}
        isLoading={loading}
        toggleEdit={toggleEdit}
        isEditingAvailable
        onSave={handleSave}
      >
        {isEditing ? (
          <TextField
            label="Title"
            value={editedTitle}
            onChange={(e) =>
              dispatch({ type: "EDIT_TITLE", payload: e.target.value })
            }
            fullWidth
            sx={{
              input: { color: "text.primary" },
              label: { color: "text.primary" },
              backgroundColor: "background.paper",
              borderRadius: "10px",
            }}
          />
        ) : (
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="body1"
              sx={{
                color: "text.primary",
                fontSize: "1rem",
                marginBottom: "8px",
              }}
            >
              <strong>Title:</strong> {originalTitle}
            </Typography>
          </Box>
        )}
      </CustomAccordion>
    </SectionWrapper>
  );
};

export default TitleStage;
