import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import CustomAccordion from "../accordion/CustomAccordion";
import axios from "axios";
import { useStoryGenerationContext } from "../../../context/StoryGenerationContext";
import SectionWrapper from "./SectionWrapper";

interface SettingStageProps {
  handleNext: () => Promise<void>;
  storyId: string;
  setAlert: (alertMsg: string) => void;
}

const SettingStage = ({ handleNext, storyId, setAlert }: SettingStageProps) => {
  const { getToken } = useAuth();
  const { storyGenerationState, updateStoryGenerationState } =
    useStoryGenerationContext();

  const [originalContent, setOriginalContent] = useState({
    setting: "",
    backdrop: "",
  });
  const [editedContent, setEditedContent] = useState({
    setting: "",
    backdrop: "",
  });
  const [loading, setLoading] = useState({
    setting: true,
    backdrop: true,
  });
  const [editing, setEditing] = useState({
    setting: false,
    backdrop: false,
  });

  const setting = storyGenerationState?.data?.find(
    (state) => state.section === "Setting",
  )?.value;

  useEffect(() => {
    if (setting) {
      // Set initial state once settings are available
      const initialContent = {
        setting: setting[0].setting || "",
        backdrop: setting[0].backdrop || "",
      };
      setOriginalContent(initialContent);
      setEditedContent(initialContent);
      setLoading({ setting: false, backdrop: false });
    }
  }, [setting]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveSetting = async (settingType: "setting" | "backdrop") => {
    setLoading((prev) => ({ ...prev, [settingType]: true }));

    try {
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-setting`,
        { storyId, [settingType]: editedContent[settingType] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const updatedValue = response.data.updated_setting[settingType];

        setOriginalContent((prev) => ({
          ...prev,
          [settingType]: updatedValue,
        }));
        setEditedContent((prev) => ({ ...prev, [settingType]: updatedValue }));
        // **Update StoryGenerationContext**
        const updatedSetting = {
          ...setting,
          0: {
            ...setting[0],
            [settingType]: updatedValue,
          },
        };

        updateStoryGenerationState({ Setting: updatedSetting });
      }
    } catch (error) {
      setAlert(`Error saving ${settingType}`);
      console.error(`Error saving ${settingType}:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [settingType]: false }));
      setEditing((prev) => ({ ...prev, [settingType]: false }));
    }
  };

  const renderSettingAccordion = (
    settingType: "setting" | "backdrop",
    label: string,
  ) => (
    <CustomAccordion
      title={label}
      isEditingAvailable={true}
      isEditing={editing[settingType]}
      isLoading={loading[settingType]}
      toggleEdit={() => {
        // same as GenresStage.tsx does
        editing[settingType] &&
          setEditedContent((prev) => ({
            ...prev,
            [settingType]: originalContent[settingType],
          }));

        setEditing((prev) => ({ ...prev, [settingType]: !prev[settingType] }));
      }}
      onSave={() => handleSaveSetting(settingType)}
    >
      {editing[settingType] ? (
        <TextField
          label={label}
          name={settingType}
          value={editedContent[settingType]}
          onChange={handleChange}
          fullWidth
          sx={{
            input: { color: "text.primary" },
            label: { color: "text.primary" },
            backgroundColor: "background.paper",
            borderRadius: "10px",
          }}
        />
      ) : (
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          {editedContent[settingType]}
        </Typography>
      )}
    </CustomAccordion>
  );

  return (
    <SectionWrapper
      canProceed={
        !editing.setting &&
        !editing.backdrop &&
        !loading.setting &&
        !loading.backdrop
      }
      handleNext={handleNext}
    >
      {renderSettingAccordion("setting", "Setting")}
      {renderSettingAccordion("backdrop", "Backdrop")}
    </SectionWrapper>
  );
};

export default SettingStage;
