import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    accordionTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    accordionTitle?: React.CSSProperties;
  }

  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    accordionTitle: true;
  }
}

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
      paper: "rgba(0, 0, 0, 0.3)",
    },
    primary: {
      main: "#28D8A9",
      light: "#5FE692",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#D9D9D9",
    },
  },
  typography: {
    fontFamily: '"Alata", "Arial", sans-serif',
    h1: {
      fontSize: "38px",
      fontWeight: 700,
      color: "#FFFFFF",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 300,
      color: "#FFFFFF",
    },
    accordionTitle: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#FFFFFF",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#FFFFFF",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#D9D9D9",
    },
    body1: {
      fontSize: "16px",
      color: "#FFFFFF",
    },
    body2: {
      fontSize: "16px",
      color: "#D9D9D9",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(59deg, #666666 0%, #292828 0%, #282D0C 54%, #290909 60%, #000000 83%)",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#28D8A9",
          color: "#000000",
          "&:hover": {
            backgroundColor: "#5FE692",
            color: "#000000",
          },
        },
        text: {
          color: "#28D8A9",
          "&:hover": {
            backgroundColor: "rgba(40, 216, 169, 0.1)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#28D8A9",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#28D8A9",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#28D8A9",
            },
            backgroundColor: "rgba(40,40,40,0.25)",
            borderRadius: "10px",
          },
          "& .MuiInputBase-input": {
            color: "#FFFFFF",
          },
          "& .MuiInputLabel-root": {
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "0",
          "&:not(:last-child)": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "80px",
          },
          minHeight: "60px",
          paddingLeft: "16px",
          paddingRight: "16px",
        },
        content: {
          margin: "16px 0",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #FFFFFF",
          boxShadow: "none",
          marginBottom: "0px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});

export default theme;
